<template>
  <div class="patientDetail">
    <v-card flat color="transparent">
     <v-app-bar flat color="transparent">
          <v-row class="mt-2">
            <!--<v-toolbar-title class="grey--text">{{
            patientSelected.fullName
          }}</v-toolbar-title>-->

            <v-spacer></v-spacer>
            <div>
              <v-btn
                class="context-button-style"
                rounded
                @click="editItem"
                small
              >
                <v-icon left>edit</v-icon>
                Editar
              </v-btn>
            </div>
            <!--<div v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="context-icon-color"
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </div>-->
          </v-row>
        </v-app-bar>

        <v-container class="mt-2">
          <!-- <v-layout row wrap align-content-space-around>
           <v-flex xs11 md3>
                <v-card-subtitle>
                  <p class="font-weight-black mt-4">Datos del paciente</p>
                  <v-spacer></v-spacer>
                </v-card-subtitle>
              </v-flex>
          </v-layout>-->
          <v-layout row wrap justify-space-around>
            <!-- <v-flex xs11 md4>
                <v-text-field
                  v-model="birthDateSelected"
                  label="fecha de nacimiento"
                 outlined                                                     
                  disabled
                  dense
                  class="input-group--focused mr-3"
                  append-icon="cake"                  
                ></v-text-field>
              </v-flex>
              <v-flex xs11 md4>
                <v-text-field
                  v-model="calculatedAge"
                  label="Edad"
                  outlined
                  dense
                  disabled
                  class="input-group--focused mr-3"
                  append-icon="face"
                ></v-text-field>
              </v-flex>-->
            <v-flex xs12 md4>
              <v-text-field
                v-model="patientSelected.sex"
                label="Sexo"
                outlined
                dense
                disabled
                class="custom-placeholer-color"
                append-icon="wc"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                v-model="patientSelected.phone"
                label="Teléfono"
                outlined
                dense
                disabled
                class="custom-placeholer-color"
                append-icon="phone"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <!-- <v-flex xs11 md4>
              <v-text-field
                v-model="patientSelected.email"
                label="Email"
                outlined
                dense
                disabled
                class="input-group--focused mr-3"
                append-icon="mail"
              ></v-text-field>
            </v-flex>
            <v-flex xs11 md4>
              <v-text-field
                v-model="patientSelected.rfc"
                label="RFC"
                outlined
                dense
                disabled
                class="input-group--focused mr-3"
                append-icon="text_snippet"
              ></v-text-field>
            </v-flex>-->
          </v-layout>
          <v-layout row wrap justify-space-around>
            <!--  <v-flex xs11 md4>
              <v-text-field
                v-model="patientSelected.bloodType"
                label="Grupo sanguíneo"
                outlined
                dense
                disabled
                class="input-group--focused mr-3"
                append-icon="bloodtype"
              ></v-text-field>
            </v-flex>-->
            <v-flex xs12 md4>
              <v-text-field
                v-model="patientSelected.typeAppointment"
                label="Tipo Paciente"
                outlined
                dense
                disabled
                class="custom-placeholer-color"
                append-icon="calendar_today"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                v-model="patientSelected.patientState"
                label="Estado"
                outlined
                dense
                disabled
                class="custom-placeholer-color"
                append-icon="perm_contact_calendar"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!--  <v-layout row wrap justify-space-around>
            <v-flex xs11 md12>
              <v-textarea
                v-model="patientSelected.address"
                label="Dirección"
                outlined
                dense
                disabled
                rows="2"
                class="input-group--focused mr-3"
                append-icon="home"
              ></v-textarea>
            </v-flex>
          </v-layout>-->
          <!-- <v-layout row wrap align-content-space-around>
            <v-flex xs11 md3>
              <v-card-subtitle>
                <p class="font-weight-black mt-4">Datos de la última nota</p>
              </v-card-subtitle>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around class="mt-5">
            <v-flex xs11 md6>
              <v-text-field
                v-model="firstNote.creationDateFormat"
                label="Fecha primer consulta"
                filled
                rounded
                dense
                readonly
                class="input-group--focused"
                append-icon="event"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs11 md6>
              <v-text-field
                v-model="lastNote.weight"
                label="Peso en Kg"
                filled
                rounded
                dense
                readonly
                class="input-group--focused"
                append-icon="line_weight"
              ></v-text-field>
            </v-flex>
            <v-flex xs11 md6>
              <v-text-field
                v-model="lastNote.size"
                label="Talla en metros"
                filled
                rounded
                dense
                readonly
                class="input-group--focused"
                append-icon="straighten"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs11 md6>
              <v-text-field
                v-model="lastNote.imc"
                label="IMC Kg/m²"
                filled
                rounded
                dense
                readonly
                class="input-group--focused"
                append-icon="accessibility"
              ></v-text-field>
            </v-flex>
            <v-flex xs11 md6>
              <v-chip
                v-if="lastNote.imc"
                filled
                rounded
                small
                class="mt-1 ml-3"
                :color="colorIMC"
                text-color="white"
                >{{ descIMC }}</v-chip
              >
            </v-flex>
          </v-layout>

          <v-layout row wrap justify-space-around>
            <v-flex xs11 md12>
              <v-textarea
                v-model="lastNote.diagnostics"
                label="Diagnósticos actuales"
                filled
                rounded
                dense
                readonly
                rows="3"
                class="input-group--focused"
                append-icon="medical_services"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs11 md12>
              <v-textarea
                v-model="lastNote.allergies"
                label="Alergias"
                filled
                rounded
                dense
                readonly
                rows="3"
                class="input-group--focused"
                append-icon="masks"
              ></v-textarea>
            </v-flex>
          </v-layout>-->
        </v-container>
    </v-card>
    <v-dialog
      v-model="AddPatientDialog"
      width="600"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <EditPatient
        :patientSelected="patientSelected"
        :headerTitle="headerTitle"
        :buttonTitle="buttonTitle"
        @closeEditPatient="closeWinEditPatient"
      />
    </v-dialog>
    <v-snackbar v-model="snackbar" bottom>
      <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import helperServices from "../Services/helperServices";
import EditPatient from "./EditPatient.vue";
import moment from "moment";

/*const defaultForm = Object.freeze({
  id: -1,
  patientId: null,
  dateNote: new Date().toISOString().substr(0, 10),
  timeNote: null,
  allergies: "",
  temperature: "",
  weight: "",
  breathingFrequency: "",
  bloodPressure: "",
  size: "",
  heartRate: "",
  oxygenSaturation: "",
  imc: "",
  isSOAP: false,
  subjective: "",
  objective: "",
  analysis: "",
  plan: "",
  diagnostics: "",
  treatment: "",
  laboratoryStudies: "",
  bloodType: "",
});*/

export default {
  components: { EditPatient },
  data() {
    return {
      isMobile: false,
      calculatedAge: "",
      //lastNote: null,
      //firstNote: null,
      //descIMC: "",
      //colorIMC: "",
      birthDateSelected: "",
      AddPatientDialog: false,
      headerTitle: "",
      buttonTitle: "",
      snackbar: false,
      messageSnackbar: "",
      rules: {
        noteDate: [(val) => (val || "").length > 0 || "Fecha requerida."],
        noteTime: [(val) => (val || "").length > 0 || "Hora requerida."],
        // imc: [
        //   (val) => val < 18.5 || "Bajo peso",
        //   (val) => (val >= 18.5 && val <= 24.9) || "Saludable",
        //   (val) => (val >= 25.0 && val <= 29.9) || "Sobrepeso",
        //   (val) => val > 30.0 || "Obesidad",
        // ],
        // imc2: [(val) => (val >= 18.5 && val <= 24.9) || "Saludable"],
        // imc3: [(val) => (val >= 25.0 && val <= 29.9) || "Sobrepeso"],
        // imc4: [(val) => val > 30.0 || "Obesidad"],
      },
    };
  },
  props: {
    patientSelected: null,
  },
  methods: {
    editItem() {
      // this.$router.push({
      //   name: "EditPatient",
      //   params: { patient: item },
      // });

      this.headerTitle = "Editar paciente";
      this.buttonTitle = "Actualizar";
      this.AddPatientDialog = true;
    },
    calcularEdad(birthDate) {
      let nacimiento = moment(birthDate); //Debe estar en formato YYYY-MM-DD
      let hoy = moment();
      let edad = 0;
      if (nacimiento < hoy) {
        edad = hoy.diff(nacimiento, "years"); //Calculamos la diferencia en años
      } //else {
      //console.error(
      // "La fecha de nacimiento no puede ser superior a la fecha actual del sistema."
      // );
      //}
      return edad;
    },
    /*calculateIMC() {
      this.$nextTick(() => {
        if (this.lastNote.weight != "" && this.lastNote.size != "") {
          if (this.lastNote.imc < 16) {
            this.descIMC = "Delgadez Severa";
            this.colorIMC = "red darken-4";
          } else if (this.lastNote.imc >= 16 && this.lastNote.imc < 16.99) {
            this.descIMC = "Delgadez Moderada";
            this.colorIMC = "red darken-1";
          } else if (this.lastNote.imc >= 17 && this.lastNote.imc < 18.49) {
            this.descIMC = "Delgadez Aceptable";
            this.colorIMC = "teal";
          } else if (this.lastNote.imc >= 18.5 && this.lastNote.imc < 24.9) {
            this.descIMC = "saludable";
            this.colorIMC = "green";
          } else if (this.lastNote.imc >= 25 && this.lastNote.imc < 29.9) {
            this.descIMC = "Sobrepeso";
            this.colorIMC = "red darken-1";
          } else if (this.lastNote.imc >= 30 && this.lastNote.imc < 34.99) {
            this.descIMC = "Obesidad Tipo I";
            this.colorIMC = "red darken-2";
          } else if (this.lastNote.imc >= 35 && this.lastNote.imc <= 40) {
            this.descIMC = "Obesidad Tipo II";
            this.colorIMC = "red darken-3";
          } else if (this.lastNote.imc > 40) {
            this.descIMC = "Obesidad Tipo III";
            this.colorIMC = "red darken-4";
          } else {
            this.descIMC = "";
          }
        }
      });
    },*/
    closeWinEditPatient(result) {
      if (result.tipo == "cerrar") {
        this.AddPatientDialog = result.isVisible;
      } else if (result.tipo == "nuevo") {
        this.AddPatientDialog = result.isVisible;
        // this.snackbar = true;
        // this.messageSnackbar = "Paciente creado correctamente.";
        this.$fire({
          position: "top-end",
          title: "Paciente creado correctamente.",
          // text: "Introduzca la información correcta.",
          type: "success",
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        // this.getPatients();
        // this.showSnackbar = true;
      } else if (result.tipo == "editar") {
        //this.patientSelected = result.item;
        this.AddPatientDialog = result.isVisible;
        //this.snackbar = true;
        //this.messageSnackbar = "Paciente modificado correctamente.";
        // this.showSnackbar = true;
        if (this.patientSelected.birthDate)
          this.birthDateSelected = this.patientSelected.birthDate;
        this.calculatedAge = this.calcularEdad(this.patientSelected.birthDate);
        this.$fire({
          position: "top-end",
          title: "Paciente modificado correctamente.",
          //text: "Introduzca la información correcta.",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      }
    },
  },
  created() {
    if (this.patientSelected.birthDate)
      this.birthDateSelected = this.patientSelected.birthDate; //moment(
    //new Date(this.patientSelected.birthDate)
    //).format("DD/MM/YYYY");

    //Source: https://www.holadevs.com/pregunta/74805/how-to-calculate-age-using-the-date-field-in-vuejs
    this.calculatedAge = this.calcularEdad(this.patientSelected.birthDate);

    // if (this.lastNote === null) {
    //   this.lastNote = Object.assign({}, defaultForm);
    // }
    // if (this.firstNote === null) {
    //   this.firstNote = Object.assign({}, defaultForm);
    // }
  },
  mounted() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
  computed: {},
};
</script>
