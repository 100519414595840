import store from '../store/index'

class UserService {
    axios
    baseUrl

    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}user`
    }
  
    GetUserByEmail(email) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetUserByEmail/?email=${email}`);
    }

    InsertUser(user) {
        let self = this;
        return self.axios.post(`${self.baseUrl}/InsertUser`, user);
    }

    InsertConsumerUser(user) {
        let self = this;
        return self.axios.post(`${self.baseUrl}/InsertConsumerUser`, user);
    }

    UpdateUser(user) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdateUser`, user);
    } 
    
    UpdateConsumerUser(user) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdateConsumerUser`, user);
    } 

    GetConsumerUsersPagination(filter) {        
        let self = this;   
        
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/GetConsumerUsers`,filter);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }

    DeleteConsumerUser(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/DeleteConsumerUser/${id}`);
    }
}

export default UserService