<template>
  <div>
    <div class="context-card">
      <v-toolbar flat class="context-toolbar">
        <v-toolbar-title>
          <div v-if="!isMobile">
            <h3>{{ infoSelected.title }}</h3>
          </div>
          <div v-else>
            <h5>{{ infoSelected.title }}</h5>
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <v-timeline dense v-for="item in listDashboardDay" :key="item.patient">
        <v-timeline-item
          large
          :color="item.scheduleColor"
          :icon="item.scheduleIcon"
        >
          <v-row class="pt-1" v-if="!isMobile">
            <!--<v-col cols="3" class="text-h6 mb-1">
                    <strong>{{ item.start }} - {{ item.end }}</strong>
                  </v-col>-->
            <v-col>
              <v-alert
                prominent
                border="left"
                colored-border
                :color="item.scheduleColorPlace"
                :icon="item.scheduleIconPlace"
                elevation="4"
                dense
                text
                class="context-card2"
              >
                <v-col cols="6">
                  <h3>{{ item.start }} - {{ item.end }}</h3>
                </v-col>
                <h5>{{ item.patient }}</h5>
                <p>
                  <span v-if="item.appointmentPlace === 'Domicilio'"
                    >Consulta {{ item.scheduleState }} en domicilio</span
                  >
                  <span v-else-if="item.appointmentPlace === 'Consultorio'"
                    >Consulta {{ item.scheduleState }} en consultorio</span
                  >
                  <span v-else-if="item.appointmentPlace === 'Video Consulta'"
                    >Video Consulta {{ item.scheduleState }}</span
                  >
                  <!--<v-icon medium :color="item.scheduleColorPlace">
                          {{ item.scheduleIconPlace }}
                        </v-icon>-->
                </p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="pt-1" v-else>
            <v-col cols="12">
              <v-alert
                prominent
                border="left"
                colored-border
                :color="item.scheduleColorPlace"
                :icon="item.scheduleIconPlace"
                elevation="4"
                dense
                text
                class="context-card2"
              >
                <h6>{{ item.start }} - {{ item.end }}</h6>
                <div>
                  <v-col>
                    <v-row>
                      <h6>{{ item.patient }}</h6>
                      <p>
                        <span v-if="item.appointmentPlace === 'Domicilio'"
                          >{{ item.scheduleState }} en domicilio</span
                        >
                        <span
                          v-else-if="item.appointmentPlace === 'Consultorio'"
                          >{{ item.scheduleState }} en consultorio</span
                        >
                        <span
                          v-else-if="item.appointmentPlace === 'Video Consulta'"
                          >{{ item.scheduleState }}</span
                        >
                      </p>
                      <!--<v-icon medium :color="item.scheduleColorPlace">
                          {{ item.scheduleIconPlace }}
                        </v-icon>-->
                    </v-row>
                  </v-col>
                </div>
              </v-alert>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </div>

    <!-- <v-simple-table class="grey lighten-3" v-if="listDashboardDay.length">
      <template v-slot:default>
        <tbody>
          <tr v-for="item in listDashboardDay" :key="item.patient">
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="mr-2"
                    :color="item.scheduleColor"
                  >
                    {{ item.scheduleIcon }}
                  </v-icon>
                </template>
                <span v-if="item.appointmentPlace === 'Domicilio'"
                  >Consulta {{ item.scheduleState }} en domicilio</span
                >
                <span v-else-if="item.appointmentPlace === 'Consultorio'"
                  >Consulta {{ item.scheduleState }} en consultorio</span
                >
                <span v-else-if="item.appointmentPlace === 'Video Consulta'"
                  >Video Consulta {{ item.scheduleState }}</span
                >
              </v-tooltip>
            </td>
            <td>{{ item.start }}-{{ item.end }}</td>
            <td>{{ item.patient }}</td>

            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="mr-2"
                    :color="item.scheduleColorPlace"
                  >
                    {{ item.scheduleIconPlace }}
                  </v-icon>
                </template>
                <span v-if="item.appointmentPlace === 'Domicilio'"
                  >Consulta {{ item.scheduleState }} en domicilio</span
                >
                <span v-else-if="item.appointmentPlace === 'Consultorio'"
                  >Consulta {{ item.scheduleState }} en consultorio</span
                >
                <span v-else-if="item.appointmentPlace === 'Video Consulta'"
                  >Video Consulta {{ item.scheduleState }}</span
                >
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <span v-else color="gray" class="text-body1 ml-5"
      >No hay consultas para hoy</span
    >-->
  </div>
</template>
<script>
import helperServices from "../Services/helperServices";

export default {
  name: "DashboardDay",
  data: () => {
    return {
      orders: [],
      isMobile: false,
    };
  },

  props: {
    infoSelected: null,
    listDashboardDay: [],
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
<style>
.space {
  margin-left: -100px;
  margin-right: -100px;
}
</style>
