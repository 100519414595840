<template>
  <div>
    <div class="context-card">
      <v-toolbar flat class="context-toolbar">
        <v-toolbar-title>
          <div v-if="!isMobile">
            <h3>{{ infoSelected.title }}</h3>
          </div>
          <div v-else>
            <h5>{{ infoSelected.title }}</h5>
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <v-responsive>
        <v-item-group mandatory class="mt-5">
          <v-row justify="space-between" align="center">
            <v-col
              cols="12"
              md="4"
              v-for="dash in infoSelected.items"
              :key="dash.name"
            >
              <v-item v-slot="{ toggle }">
                <div
                  class="context-card2"
                  max-width="350"
                  @click="toggle"
                  elevation="10"
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <h5 class="text-overline mb-2">{{ dash.name }}</h5>
                      <v-list-item-title>
                        <h1>{{ dash.total }}</h1>
                      </v-list-item-title>
                      <!--    <v-list-item-subtitle
                      >Greyhound divisely hello coldly
                      fonwderfully</v-list-item-subtitle
                    >-->
                    </v-list-item-content>
                    <v-list-item-avatar
                      tile
                      size="50"
                      :color="dash.color"
                      class="rounded-xl"
                    >
                      <v-icon x-large color="white">{{ dash.icon }}</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </div>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-responsive>
    </div>
  </div>
</template>
<script>
import helperServices from "../Services/helperServices";
export default {
  name: "DashboardMonth",
  data: () => {
    return {
      toggle_exclusive: 1,
      isMobile: false,
    };
  },
  props: {
    infoSelected: null,
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
<style>
.space {
  margin-left: -100px;
  margin-right: -100px;
}
</style>
