<template>
  <!-- Payment Component-->
  <div id="app" v-bind:class="currentTheme">
    <div class="context-title">
      <v-app-bar color="transparent" flat elevate-on-scroll>
        <v-btn
          class="context-button-color"
          icon
          bottom
          right
          offset-y
          @click="$router.push('/paymentCatalog')"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>

        <h1>{{ planSelected.text }}</h1>
      </v-app-bar>
    </div>
    <div class="context-card">
      <div class="context-title"><h3>Pago del servicio</h3></div>
      <v-row justify="center" class="fill-height">
        <v-col cols="12">
          <div style="display: flex; justify-content: center">
            <div class="sheet-container">
              <v-card>
                <v-card-text v-if="planSelected.discount">
                  <div class="paypal-title">{{ planSelected.title }}</div>
                  <div class="paypal-text">
                    <span>Subtotal (MXN) </span>
                    <span>${{ planSelected.amount }}</span>
                  </div>
                  <div class="paypal-text">
                    <span>{{ planSelected.titleDiscount }} </span>
                    <span>${{ planSelected.discount }}</span>
                  </div>
                  <div class="paypal-text">
                    <span>Total (MXN) </span>
                    <span>${{ planSelected.amountDiscount }}</span>
                  </div>
                  <v-divider class="mt-4 mb-4"></v-divider>
                  <div
                    v-if="planSelected.title === 'Suscripción mensual'"
                    ref="paypalSuscription"
                  ></div>
                  <div v-else ref="paypalPayment"></div>
                </v-card-text>
                <v-card-text v-else>
                  <div class="paypal-title">{{ planSelected.title }}</div>
                  <div class="paypal-text">
                    <span>Total (MXN) </span>
                    <span>${{ planSelected.amount }}</span>
                  </div>
                  <v-divider class="mt-4 mb-4"></v-divider>
                  <div
                    v-if="planSelected.title === 'Suscripción mensual'"
                    ref="paypalSuscription"
                  ></div>
                  <div v-else ref="paypalPayment"></div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import apiServices from "../Services/apiServices";
export default {
  name: "Payment",
  data() {
    return {
      planSelected: "",
      loaded: false,
      paidFor: false,
      currentTheme: localStorage.getItem("theme-color"),
    };
  },

  //https://developer.paypal.com/sdk/js/reference/
  //https://developer.paypal.com/docs/subscriptions/
  //https://www.tipsandtricks-hq.com/paypal-test-cards-sandbox-testing-11641
  mounted: function () {
    this.planSelected = this.$route.params.planSelected;
    var paypalConfig = JSON.parse(localStorage.getItem("paypalConfig"));
    // 2. Mount paypal button
    if (this.planSelected) {
      const script = document.createElement("script");
      if (this.planSelected.title === "Suscripción mensual") {
        script.src = paypalConfig.clientIdSubscription;
        //"https://www.paypal.com/sdk/js?client-id=Ae9gD22rIBPNNJ_vj0OsuQdif8FgbFqvk_EeYnPo4S1h_X7S7T2MDzPCUwD9Ju5lGUAMMa-GMLCmqEth&currency=MXN&locale=es_MX&vault=true&intent=subscription";
        script.addEventListener("load", this.setLoadedSuscription);
      } else {
        script.src = script.src = paypalConfig.clientIdPayment;
        //"https://www.paypal.com/sdk/js?client-id=Ae9gD22rIBPNNJ_vj0OsuQdif8FgbFqvk_EeYnPo4S1h_X7S7T2MDzPCUwD9Ju5lGUAMMa-GMLCmqEth&currency=MXN&locale=es_MX";
        script.addEventListener("load", this.setLoadedPayment);
      }
      document.body.appendChild(script);
    }
  },

  // methods
  methods: {
    setLoadedSuscription: function () {
      this.loaded = true;
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "subscribe",
          },
          createSubscription: function (data, actions) {
            var paypalConfig = JSON.parse(localStorage.getItem("paypalConfig"));
            return actions.subscription.create({
              /* Creates the subscription */
              //plan_id: "P-96R79053U8565022FMIVGUSA",
              plan_id: paypalConfig.planIdSubscription,
            });
          },
          onApprove: function (data, actions) {
            //si el pago es aprobado, lo guardo en la BD
            console.log(actions);
            var plan = JSON.parse(localStorage.getItem("planSelected"));
            let item = {
              monthsPaid: plan.monthsPaid,
              status: "",
              amount: plan.amount,
              discount: plan.discount,
              amountDiscount: plan.amountDiscount,
              currencyCode: plan.currencyCode,
              description: plan.subtitle + " " + plan.title,
              operationId: data.subscriptionID,
              payerFullName: "",
              payerEmail: "",
              payerId: "",
              payerAddress: "",
              lastPaymentTime: "",
              nextBillingTime: "",
              creationTime: "",
              updateTime: "",
            };

            //alert(data.subscriptionID); // You can add optional success message for the subscriber here
            apiServices.PaypalInformationService.InsertPaypalSubscriptionInformation(
              item
            )
              .then(() => {
                Swal.fire({
                  icon: "success",
                  title: "Felicidades!!!",
                  text: "Tu suscripción ha sido procesada correctamente!",
                  confirmButtonText: "Completado",
                  showLoaderOnConfirm: true,
                  preConfirm: () => {
                    // redirect user to dashboard or login
                    //location.assign("http://localhost:8080/login");
                    this.$router.push({
                      name: "login",
                    });
                  },
                  allowOutsideClick: false,
                });
                localStorage.removeItem("planSelected");
              })
              .catch((error) => {
                if (error.response) {
                  // get response with a status code not in range 2xx
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // no response
                  console.log(error.request);
                  // instance of XMLHttpRequest in the browser
                  // instance ofhttp.ClientRequest in node.js
                } else {
                  // Something wrong in setting up the request
                  console.log("Error", error.message);
                }
                console.log("Error.config: " + error.config);
              });
          },
          onerror: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypalSuscription);
    },
    setLoadedPayment() {
      this.loaded = true;
      let self = this;
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "paypal",
          },
          createOrder: function (data, actions) {
            var plan = JSON.parse(localStorage.getItem("planSelected"));
            var totalAmount = null;
            if (plan.discount) {
              totalAmount = plan.amountDiscount;
            } else {
              totalAmount = plan.amount;
            }
            return actions.order.create({
              // intent: "CAPTURE",
              purchase_units: [
                {
                  description: plan.subtitle + " " + plan.title,
                  amount: {
                    currency_code: plan.currencyCode,
                    value: totalAmount,
                  },
                },
              ],
            });
          },

          onApprove: function (data, actions) {
            return actions.order.capture().then(function (details) {
              //si el pago es aprobado, lo guardo en la BD

              var plan = JSON.parse(localStorage.getItem("planSelected"));
              let item = {
                monthsPaid: plan.monthsPaid,
                status: details.status,
                amount: plan.amount,
                discount: plan.discount,
                amountDiscount: plan.amountDiscount,
                currencyCode: plan.currencyCode,
                description: plan.subtitle + " " + plan.title,
                operationId: details.id,
                payerFullName:
                  details.payer.name.given_name +
                  " " +
                  details.payer.name.surname,
                payerEmail: details.payer.email_address,
                payerId: details.payer.payer_id,
                payerAddress: "",
                lastPaymentTime: details.update_time,
                nextBillingTime: "",
                creationTime: details.create_time,
                updateTime: details.update_time,
              };

              apiServices.PaypalInformationService.InsertPaypalPaymentInformation(
                item
              )
                .then(() => {
                  Swal.fire({
                    icon: "success",
                    title: "Felicidades!!!",
                    text: "Tu pago ha sido procesado correctamente!",
                    confirmButtonText: "Completado",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                      // redirect user to dashboard or login
                      //location.assign("http://localhost:8080/login");
                      self.$store.dispatch("fetchUser", null);
                      localStorage.removeItem("paypalConfig");
                      self.$router.push({
                        name: "Login",
                      });
                    },
                    allowOutsideClick: false,
                  });
                  localStorage.removeItem("planSelected");
                })
                .catch((error) => {
                  if (error.response) {
                    // get response with a status code not in range 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    // no response
                    console.log(error.request);
                    // instance of XMLHttpRequest in the browser
                    // instance ofhttp.ClientRequest in node.js
                  } else {
                    // Something wrong in setting up the request
                    console.log("Error", error.message);
                  }
                  console.log("Error.config: " + error.config);
                });
            });
          },
          onerror: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypalPayment);
    },
    // Mount paypal button
    // SuscriptionPaypalbutton() {
    // eslint-disable-next-line no-undef
    //   paypal
    //     .Buttons({
    //       style: {
    //         shape: "rect",
    //         color: "blue",
    //         layout: "vertical",
    //         label: "paypal",
    //         size: "medium",
    //       },
    //       createSubscription: async function (data, actions) {
    //        // 1. get the selected package
    //        const package_response = await localStorage.getItem(
    //          "@selectedpackage"
    //        );
    //       const package_results = JSON.parse(package_response);
    //        let plan_ID = package_results.plan_id; // user plan id
    // 2. Create a subscription
    //       return actions.subscription.create({
    //         plan_id: plan_ID,
    //        });
    //      },
    // eslint-disable-next-line no-unused-vars
    //      onApprove: async function (data, actions) {
    /**
     * NOTE
     * - Save the subscription id in your Database
     * - This is important to ensure you can always
     * - Check on the status when user logs in or wants
     * - to make payment
     */

    // 2. Save payment subscription id
    //let subscrption_id = data.subscriptionID;

    // 4. Remove the selected package from the local storage

    //        localStorage.removeItem("@selectedpackage");

    // 5. Lets use swal to give us an alert once transcation is completed
    //        Swal.fire({
    //          icon: "success",
    //          title: "Congratulations",
    //          text: "Your payment has successfully been proccessed!",
    //          confirmButtonText: "Complete",
    //          showLoaderOnConfirm: true,
    //          preConfirm: () => {
    // redirect user to dashboard or login
    //            location.assign("http://localhost:8080/profile");
    //         },
    //         allowOutsideClick: false,
    //       });
    //     },
    //   })
    //   .render("#paypal-button-container");
    //},
  },
};
</script>

<style scooped lang="css">
@media only screen and (max-width: 600px) {
  .sheet-container {
    border: 1px solid #e0e0e0;
    width: 300px;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 960px) {
  .sheet-container {
    border: 1px solid #e0e0e0;
    width: 350px;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 960px) {
  .sheet-container {
    border: 1px solid #e0e0e0;
    width: 400px;
    border-radius: 5px;
  }
}
.paypal-title {
  -webkit-font-smoothing: antialiased;
  color: black;
  text-transform: uppercase;
  font-size: 1.1em;
  margin-bottom: 5px;
}
.paypal-text {
  -webkit-font-smoothing: antialiased;
  color: black;
  font-size: 1.2em;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
