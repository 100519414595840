<template>
  <div class="medicalNote">
    <v-row class="mt-5">
      <!--<v-toolbar-title>
            <v-card-title class="grey--text"> Nota médica </v-card-title>
          </v-toolbar-title>-->

      <template v-if="!isMobile">
        <v-spacer />
        <v-btn
          class="context-button-style mr-1"
          rounded
          :disabled="!formIsValid"
          @click="submit"
          :loading="isLoading"
          small
        >
          <v-icon left>save</v-icon>
          Guardar
        </v-btn>
        <v-btn
          class="context-button-style mr-1"
          rounded
          :disabled="!formIsValid"
          @click="print()"
          small
        >
          <v-icon left>print</v-icon>
          Imprimir
        </v-btn>
        <v-btn
          class="context-button-style mr-5"
          rounded
          :disabled="!CancelIsValid"
          @click="resetForm()"
          small
        >
          <v-icon left>cancel</v-icon>
          Cancelar
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          class="context-button-style"
          rounded
          :disabled="!formIsValid"
          @click="submit"
          :loading="isLoading"
          x-small
          width="95"
        >
          <v-icon left>save</v-icon>
          Guardar
        </v-btn>
        <v-btn
          class="context-button-style ml-1"
          rounded
          :disabled="!formIsValid"
          @click="print()"
          x-small
          width="85"
        >
          <v-icon left>print</v-icon>
          Imprimir
        </v-btn>
        <v-btn
          class="context-button-style ml-1"
          rounded
          :disabled="!CancelIsValid"
          @click="resetForm()"
          x-small
          width="95"
        >
          <v-icon left>cancel</v-icon>
          Cancelar
        </v-btn>
      </template>
      <!--<v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="context-icon-color"
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            :disabled="!formIsValid"
            @click="submit"
            :loading="isLoading"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>
        <span>Guardar</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="context-icon-color"
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            :disabled="!formIsValid"
            @click="print()"
          >
            <v-icon>print</v-icon>
          </v-btn>
        </template>
        <span>Imprimir</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="context-icon-color"
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            :disabled="!CancelIsValid"
            @click="resetForm()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <span>Cancelar</span>
      </v-tooltip>-->
    </v-row>
    <!--cuando es escritorio-->
    <v-toolbar rounded="xl" flat v-if="!isMobile" class="context-toolbar mt-3">
      <v-toolbar-title class="mt-5">
        <v-row class="ma-2">
          <v-flex xs4 md4>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Fecha"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  class="custom-placeholer-color mr-3 ma-2"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="noteSelected.dateNote"
                no-title
                :min="new Date().toISOString().substr(0, 10)"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs3 md3>
            <v-menu
              ref="menu"
              v-model="modalHora"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="noteSelected.timeNote"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="noteSelected.timeNote"
                  label="Hora"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  class="custom-placeholer-color mr-3 ma-2"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modalHora"
                v-model="noteSelected.timeNote"
                full-width
                no-title
                @click:minute="$refs.menu.save(noteSelected.timeNote)"
              ></v-time-picker>
            </v-menu>
          </v-flex>
          <v-flex xs5 md5>
            <v-badge
              bordered
              color="primary"
              overlap
              :content="badgeIMC"
              class="ma-2"
            >
              <v-autocomplete
                v-model="lastNote"
                :items="notesBD"
                label="Notas anteriores"
                full-width
                append-icon="description"
                rounded
                filled
                dense
                outlined
                item-text="creationDateFormat"
                item-value="id"
                return-object
                @change="(note) => setLastNote(note)"
              ></v-autocomplete>
            </v-badge>
          </v-flex>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="context-icon-color"
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            @click="backNote()"
            :disabled="backButtonDisabled"
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>
        </template>
        <span>Nota anterior</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="context-icon-color"
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            @click="nextNote()"
            :disabled="nextButtonDisabled"
          >
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </template>
        <span>Nota siguiente</span>
      </v-tooltip>
    </v-toolbar>
    <!--cuando es movil-->
    <v-toolbar rounded="xl" flat class="context-toolbar mt-3" v-else>
        <v-badge
            bordered
            color="primary"
            overlap
            :content="badgeIMC"            
          >
            <v-autocomplete
              v-model="lastNote"
              :items="notesBD"
              label="Notas anteriores"
              full-width
              append-icon="description"
              rounded
              filled
              dense
              outlined
              item-text="creationDateFormat"
              item-value="id"
              return-object
              @change="(note) => setLastNote(note)"
            ></v-autocomplete>
          </v-badge>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="context-icon-color mb-3"
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            @click="backNote()"
            :disabled="backButtonDisabled"
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>
        </template>
        <span>Nota anterior</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="context-icon-color mb-3"
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            @click="nextNote()"
            :disabled="nextButtonDisabled"
          >
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </template>
        <span>Nota siguiente</span>
      </v-tooltip>
    </v-toolbar>

    <v-container class="mt-4">
      <!--si es mobile pongo dentro del card la fecha, hora-->
      <v-layout row wrap justify-space-around v-if="isMobile">
        <v-flex xs6 md6>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Fecha"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  class="custom-placeholer-color"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="noteSelected.dateNote"
                no-title
                :min="new Date().toISOString().substr(0, 10)"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs5 md5 class="ml-2">
            <v-menu
              ref="menu"
              v-model="modalHora"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="noteSelected.timeNote"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="noteSelected.timeNote"
                  label="Hora"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  class="custom-placeholer-color"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modalHora"
                v-model="noteSelected.timeNote"
                full-width
                no-title
                @click:minute="$refs.menu.save(noteSelected.timeNote)"
              ></v-time-picker>
            </v-menu>
          </v-flex>
      </v-layout>

      <v-layout row wrap align-content-space-around>
        <v-flex xs12 md12>
          <v-card-subtitle>
            <h5>Alergias</h5>
          </v-card-subtitle>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md12>
          <v-textarea
            v-model="noteSelected.allergies"
            outlined
            label="Agregar alergias"
            dense
            rows="3"
            append-icon="masks"
            class="custom-placeholer-color"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout row wrap align-content-space-around>
        <v-flex xs12 md12>
          <v-card-subtitle>
            <h5>Signos vitales y somatometría</h5>
          </v-card-subtitle>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-space-around>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.temperature"
            label="Temperatura"
            suffix="°C"
            outlined
            dense
            type="number"
            v-on:keypress="DecimalOnly"
            class="custom-placeholer-color"
            prepend-inner-icon="thermostat"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.breathingFrequency"
            label="Frecuencia respiratoria"
            suffix="/min"
            outlined
            dense
            type="text"
            v-on:keypress="NumbersOnly"
            maxlength="3"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.heartRate"
            label="Frecuencia cardiaca"
            suffix="/min"
            outlined
            dense
            type="text"
            v-on:keypress="NumbersOnly"
            maxlength="3"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.bloodPressure"
            label="Presión arterial"
            suffix="mmHg"
            outlined
            dense
            hint="el formato es 000/000"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.oxygenSaturation"
            label="Saturación de oxígeno"
            suffix="%"
            outlined
            dense
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.weight"
            label="Peso"
            suffix="Kg"
            outlined
            dense
            @input="calculateIMC"
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.size"
            label="Talla"
            suffix="m"
            outlined
            dense
            @input="calculateIMC"
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md12>
          <v-text-field
            v-model="noteSelected.imc"
            label="Índice de masa corporal"
            suffix="Kg/m²"
            outlined
            dense
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md12 v-if="noteSelected.imc">
          <v-chip
            filled
            rounded
            small
            class="mt-0 ma-2"
            :color="colorIMC"
            text-color="white"
            >{{ descIMC }}</v-chip
          >
        </v-flex>
      </v-layout>
      <v-layout row wrap align-content-space-around>
        <v-flex xs12 md12>
          <v-card-subtitle>
            <h5>Nota médica</h5>
            <v-checkbox
              class="ml-5"
              v-model="noteSelected.isSOAP"
              label="Usar SOAP"
            ></v-checkbox>
          </v-card-subtitle>
        </v-flex>
      </v-layout>

      <div v-if="noteSelected.isSOAP">
        <v-layout row wrap justify-space-around>
          <v-flex xs12 md12>
            <v-textarea
              v-model="noteSelected.subjective"
              label="Subjetivo"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-space-around>
          <v-flex xs12 md12>
            <v-textarea
              v-model="noteSelected.objective"
              label="Objetivo"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-space-around>
          <v-flex xs12 md12>
            <v-textarea
              v-model="noteSelected.analysis"
              label="Análisis"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-space-around>
          <v-flex xs12 md12>
            <v-textarea
              v-model="noteSelected.plan"
              label="Plan"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </div>
      <div v-else>
        <v-layout row wrap justify-space-around>
          <v-flex xs12 md12>
            <v-textarea
              v-model="noteSelected.note"
              label="Nota médica"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </div>
      <v-layout row wrap align-content-space-around>
        <v-flex xs12 md12>
          <v-card-subtitle>
            <h5>Diagnósticos</h5>
          </v-card-subtitle>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md12>
          <v-textarea
            v-model="noteSelected.diagnostics"
            label="Diagnósticos"
            outlined
            dense
            rows="3"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout row wrap align-content-space-around>
        <v-flex xs12 md12>
          <v-card-subtitle>
            <h5>Tratamiento</h5>
          </v-card-subtitle>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md12>
          <v-textarea
            v-model="noteSelected.treatment"
            label="Tratamiento"
            outlined
            dense
            rows="3"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout row wrap align-content-space-around>
        <v-flex xs12 md12>
          <v-card-subtitle>
            <h5>Estudios de laboratorio y gabinete</h5>
          </v-card-subtitle>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md12>
          <v-textarea
            v-model="noteSelected.laboratoryStudies"
            label="Estudios para la siguiente consulta"
            outlined
            dense
            rows="3"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar" bottom>
      <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import helperServices from "../Services/helperServices";
import apiServices from "../Services/apiServices";

const defaultForm = Object.freeze({
  id: -1,
  patientId: null,
  dateNote: new Date().toISOString().substr(0, 10),
  timeNote: null,
  allergies: "",
  temperature: "",
  weight: "",
  breathingFrequency: "",
  bloodPressure: "",
  size: "",
  heartRate: "",
  oxygenSaturation: "",
  imc: "",
  isSOAP: false,
  note: "",
  subjective: "",
  objective: "",
  analysis: "",
  plan: "",
  diagnostics: "",
  treatment: "",
  laboratoryStudies: "",
});

export default {
  components: {},
  data() {
    return {
      noteSelected: null,
      lastNote: null,
      isMobile: false,
      notesBD: [],
      notesCount: 0,
      noteIndex: 0,
      noteIndexFlag: false,
      noteIndexFlag2: false,
      nextButtonDisabled: true,
      backButtonDisabled: true,
      descIMC: "",
      colorIMC: "",
      badgeIMC: 0,
      snackbar: false,
      messageSnackbar: "",
      isLoading: false,
      menu2: false,
      modalHora: false,
      rules: {
        noteDate: [(val) => (val || "").length > 0 || "Fecha requerida."],
        noteTime: [(val) => (val || "").length > 0 || "Hora requerida."],
        // imc: [
        //   (val) => val < 18.5 || "Bajo peso",
        //   (val) => (val >= 18.5 && val <= 24.9) || "Saludable",
        //   (val) => (val >= 25.0 && val <= 29.9) || "Sobrepeso",
        //   (val) => val > 30.0 || "Obesidad",
        // ],
        // imc2: [(val) => (val >= 18.5 && val <= 24.9) || "Saludable"],
        // imc3: [(val) => (val >= 25.0 && val <= 29.9) || "Sobrepeso"],
        // imc4: [(val) => val > 30.0 || "Obesidad"],
      },
    };
  },
  props: {
    patientSelected: null,
  },
  methods: {
    print() {
      //parametros para imprimir
      var titleDoc = "Reporte Agenda de pacientes"; //titulo de la pestaña, nuevo documento
      var titleReport = "Nota médica"; //titulo del reporte
      var orientationDescription = "portrait";
      var pageSizeDescription = "A4";

      //datos para imprimir
      helperServices.PrintService.printerMedicalNote(
        titleDoc,
        titleReport,
        orientationDescription,
        pageSizeDescription,
        this.patientSelected,
        this.noteSelected
      );
    },
    resetForm() {
      this.noteSelected = Object.assign({}, defaultForm);
      this.lastNote = null;
      this.noteIndex = 0;
      //this.$refs.formulario.reset();
      this.backButtonDisabled = false;
      this.nextButtonDisabled = true;
      this.noteIndexFlag2 = false;

      this.setDateAndTime();
    },
    validNoteSelected() {
      //si hay campos que no se llenan se pone el valor default
      if (this.noteSelected.allergies === null)
        this.noteSelected.allergies = "";
      if (this.noteSelected.analysis === null) this.noteSelected.analysis = "";
      if (this.noteSelected.bloodPressure === null)
        this.noteSelected.bloodPressure = "";
      if (this.noteSelected.breathingFrequency === null)
        this.noteSelected.breathingFrequency = "";
      if (this.noteSelected.diagnostics === null)
        this.noteSelected.diagnostics = "";
      if (this.noteSelected.heartRate === null)
        this.noteSelected.heartRate = "";
      if (this.noteSelected.imc === null) this.noteSelected.imc = "";
      if (this.noteSelected.isSOAP === null) this.noteSelected.isSOAP = false;
      if (this.noteSelected.laboratoryStudies === null)
        this.noteSelected.laboratoryStudies = "";
      if (this.noteSelected.objective === null)
        this.noteSelected.objective = "";
      if (this.noteSelected.oxygenSaturation === null)
        this.noteSelected.oxygenSaturation = "";
      if (this.noteSelected.patientId === null)
        this.noteSelected.patientId = this.patientSelected.id;
      if (this.noteSelected.plan === null) this.noteSelected.plan = "";
      if (this.noteSelected.size === null) this.noteSelected.size = "";
      if (this.noteSelected.subjective === null)
        this.noteSelected.subjective = "";
      if (this.noteSelected.temperature === null)
        this.noteSelected.temperature = "";
      if (this.noteSelected.treatment === null)
        this.noteSelected.treatment = "";
      if (this.noteSelected.weight === null) this.noteSelected.weight = "";
      if (this.noteSelected.note === null) this.noteSelected.note = "";
    },
    submit() {
      this.isLoading = true;
      this.validNoteSelected();

      let item = {
        id: 0,
        patientId: this.patientSelected.id,
        dateNote: this.noteSelected.dateNote,
        timeNote: this.noteSelected.timeNote,
        allergies: this.noteSelected.allergies,
        temperature: this.noteSelected.temperature,
        weight: this.noteSelected.weight,
        breathingFrequency: this.noteSelected.breathingFrequency,
        bloodPressure: this.noteSelected.bloodPressure,
        size: this.noteSelected.size,
        heartRate: this.noteSelected.heartRate,
        oxygenSaturation: this.noteSelected.oxygenSaturation,
        imc: this.noteSelected.imc,
        isSOAP: this.noteSelected.isSOAP,
        note: "",
        subjective: "",
        objective: "",
        analysis: "",
        plan: "",
        diagnostics: this.noteSelected.diagnostics,
        treatment: this.noteSelected.treatment,
        laboratoryStudies: this.noteSelected.laboratoryStudies,
      };

      if (item.isSOAP) {
        item.note = "";
        item.subjective = this.noteSelected.subjective;
        item.objective = this.noteSelected.objective;
        item.analysis = this.noteSelected.analysis;
        item.plan = this.noteSelected.plan;
      } else {
        item.note = this.noteSelected.note;
        item.subjective = "";
        item.objective = "";
        item.analysis = "";
        item.plan = "";
      }

      //actualizo la hora
      /* var dt = new Date();

      var itemhora = dt.getHours();
      if (itemhora <= 9) itemhora = "0" + itemhora;
      var itemminutos = dt.getMinutes();
      if (itemminutos <= 9) itemminutos = "0" + itemminutos;
      item.timeNote = itemhora + ":" + itemminutos;*/

      if (this.noteSelected.id == -1) {
        //Se inserta un nuevo registro
        apiServices.NoteService.InsertNote(item)
          .then(() => {
            this.resetForm();
            this.getNotes();
            // this.$router.push({
            // name: "Patients",
            //  params: { isNewPatient: true },
            // });
            this.closeWindow("insertMedicalNote");
            // this.snackbar = true;
            // this.messageSnackbar = "Nota creada correctamente.";
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      } else {
        item["id"] = this.noteSelected.id;
        apiServices.NoteService.UpdateNote(item)
          .then(() => {
            this.resetForm();
            this.getNotes();
            // this.$router.push({
            //   name: "Patients",
            //   params: { isNewPatient: false },
            // });
            this.closeWindow("editMedicalNote");
            this.isLoading = false;
            //this.snackbar = true;
            //this.messageSnackbar = "Nota modificada correctamente.";
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
    },
    setDateAndTime() {
      //asigno la fecha actual
      const current = new Date();
      var mes = current.getMonth() + 1;
      mes = mes < 10 ? "0" + mes : mes;
      var dia = current.getDate();
      dia = dia < 10 ? "0" + dia : dia;
      const date = `${current.getFullYear()}-${mes}-${dia}`;
      this.noteSelected.dateNote = date; //new Date().toISOString().slice(0, 10);

      //asigno la hora actual
      var dt = new Date();
      var itemhora = dt.getHours();
      var itemminutos = dt.getMinutes();

      if (itemhora <= 9) itemhora = "0" + itemhora;
      if (itemminutos <= 9) itemminutos = "0" + itemminutos;

      this.noteSelected.timeNote = itemhora + ":" + itemminutos;
    },

    closeWindow(value) {
      if (value == "editMedicalNote") {
        let result = {
          tipo: "editMedicalNote",
        };
        this.$emit("closeEditPatient", result);
      } else if (value == "insertMedicalNote") {
        let result = {
          tipo: "insertMedicalNote",
        };
        this.$emit("closeEditPatient", result);
      } else if (value == "lastNote") {
        if (this.notesBD.length) {
          //mando la primera y la ultima nota
          if (this.notesCount > 0) {
            let result = {
              tipo: "informationNote",
              lastNote: this.notesBD[0],
              firstNote: this.notesBD[this.notesCount],
            };

            this.$emit("closeEditPatient", result);
          } else {
            let result = {
              tipo: "informationNote",
              lastNote: this.notesBD[0],
              firstNote: this.notesBD[0],
            };

            this.$emit("closeEditPatient", result);
          }
        } else {
          let result = {
            tipo: "lastNote",
            lastNote: Object.assign({}, defaultForm),
            firstNote: Object.assign({}, defaultForm),
          };
          this.$emit("closeEditPatient", result);
        }
      } else if (value == "printMedicalNote") {
        let result = {
          tipo: "printMedicalNote",
        };
        this.$emit("closeEditPatient", result);
      }
    },
    getNotes() {
      apiServices.NoteService.GetNotesByPatient(this.patientSelected.id)
        .then((result) => {
          this.notesBD = result.data;
          this.notesCount = this.notesBD.length - 1;
          this.noteIndex = 0;
          if (this.notesBD.length) {
            this.backButtonDisabled = false;
            this.badgeIMC = this.notesBD.length;
            // this.nextButtonDisabled = true;
          } else {
            this.backButtonDisabled = true;
            this.badgeIMC = "0";
            //   this.nextButtonDisabled = true;
          }

          this.closeWindow("lastNote");
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    setLastNote(note) {
      if (note != null) {
        this.noteSelected = note;
        this.noteIndex = note.index;
        this.noteIndexFlag2 = true;
        this.calculateIMC();
        //this.noteIndexFlag = true;
        //deshabilito el boton siguiente
        if (this.noteIndex === this.notesCount) {
          this.backButtonDisabled = true;
        } else {
          this.backButtonDisabled = false;
        }
        this.nextButtonDisabled = false;
      }
    },

    nextNote() {
      //if (this.noteIndexFlag) {
      //  this.noteIndex = this.noteIndex - 1;
      //  this.noteIndexFlag = false;
      // }

      if (this.noteIndex > 0) {
        this.noteIndex = this.noteIndex - 1;
        this.noteSelected = this.notesBD[this.noteIndex];
        this.lastNote = this.noteSelected;
        this.backButtonDisabled = false;
        this.calculateIMC();
        this.noteIndexFlag2 = true;
      } else if (this.noteIndex === 0) {
        this.resetForm();
        this.noteIndexFlag2 = false;
      }

      //console.log(this.noteIndex);
      // if (this.noteIndex > 0) {
      //   this.noteIndex = this.noteIndex - 1;
      //   this.noteSelected = this.notesBD[this.noteIndex];
      //   this.lastNote = this.noteSelected;
      //   this.backButtonDisabled = false;
      //   this.noteIndexFlag2 = true;
      // } else if (this.noteIndex === 0) this.resetForm();
    },
    backNote() {
      if (this.noteIndexFlag2) {
        this.noteIndex = this.noteIndex + 1;
        this.noteIndexFlag2 = false;
      }
      // console.log(this.noteIndex);

      this.noteSelected = this.notesBD[this.noteIndex];
      this.noteIndex = this.noteIndex + 1;

      this.lastNote = this.noteSelected;
      //this.noteIndexFlag = true;
      // if (this.notesCount > 0) this.noteIndexFlag = true;
      // else this.noteIndexFlag = false;
      this.nextButtonDisabled = false;
      this.calculateIMC();
      if (this.noteIndex > this.notesCount) {
        this.noteIndex = this.notesCount;
        this.backButtonDisabled = true;
      }
    },
    calculateIMC() {
      this.$nextTick(() => {
        if (this.noteSelected.weight != "" && this.noteSelected.size != "") {
          this.noteSelected.imc = parseFloat(
            parseFloat(this.noteSelected.weight) /
              (parseFloat(this.noteSelected.size) *
                parseFloat(this.noteSelected.size))
          ).toFixed(2);
          if (this.noteSelected.imc < 16) {
            this.descIMC = "Delgadez Severa";
            this.colorIMC = "red darken-4";
          } else if (
            this.noteSelected.imc >= 16 &&
            this.noteSelected.imc < 16.99
          ) {
            this.descIMC = "Delgadez Moderada";
            this.colorIMC = "red darken-1";
          } else if (
            this.noteSelected.imc >= 17 &&
            this.noteSelected.imc < 18.49
          ) {
            this.descIMC = "Delgadez Aceptable";
            this.colorIMC = "teal";
          } else if (
            this.noteSelected.imc >= 18.5 &&
            this.noteSelected.imc < 24.9
          ) {
            this.descIMC = "saludable";
            this.colorIMC = "green";
          } else if (
            this.noteSelected.imc >= 25 &&
            this.noteSelected.imc < 29.9
          ) {
            this.descIMC = "Sobrepeso";
            this.colorIMC = "red darken-1";
          } else if (
            this.noteSelected.imc >= 30 &&
            this.noteSelected.imc < 34.99
          ) {
            this.descIMC = "Obesidad Tipo I";
            this.colorIMC = "red darken-2";
          } else if (
            this.noteSelected.imc >= 35 &&
            this.noteSelected.imc <= 40
          ) {
            this.descIMC = "Obesidad Tipo II";
            this.colorIMC = "red darken-3";
          } else if (this.noteSelected.imc > 40) {
            this.descIMC = "Obesidad Tipo III";
            this.colorIMC = "red darken-4";
          } else {
            this.noteSelected.imc = "";
            this.descIMC = "";
          }
        } else {
          this.noteSelected.imc = "";
        }
      });
    },
    NumbersOnly: (event) => {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    DecimalOnly($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.noteSelected.temperature.indexOf(".") !== -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 1 decimal place
      if (
        this.noteSelected.temperature !== null &&
        this.noteSelected.temperature.indexOf(".") > -1 &&
        this.noteSelected.temperature.split(".")[1].length > 0
      ) {
        $event.preventDefault();
      }
    },
  },
  created() {
    this.noteSelected = Object.assign({}, defaultForm);
    this.setDateAndTime();
    this.getNotes();
    this.backButtonDisabled = false;
  },
  mounted() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
  computed: {
    formIsValid() {
      return (
        this.noteSelected.dateNote &&
        this.noteSelected.timeNote &&
        (this.noteSelected.allergies ||
          this.noteSelected.temperature ||
          this.noteSelected.weight ||
          this.noteSelected.breathingFrequency ||
          this.noteSelected.bloodPressure ||
          this.noteSelected.size ||
          this.noteSelected.heartRate ||
          this.noteSelected.oxygenSaturation ||
          this.noteSelected.imc ||
          this.noteSelected.note ||
          this.noteSelected.subjective ||
          this.noteSelected.objective ||
          this.noteSelected.analysis ||
          this.noteSelected.plan ||
          this.noteSelected.diagnostics ||
          this.noteSelected.treatment ||
          this.noteSelected.laboratoryStudies)
      );
    },
    CancelIsValid() {
      return (
        this.noteSelected.dateNote ||
        this.noteSelected.timeNote ||
        this.noteSelected.allergies ||
        this.noteSelected.temperature ||
        this.noteSelected.weight ||
        this.noteSelected.breathingFrequency ||
        this.noteSelected.bloodPressure ||
        this.noteSelected.size ||
        this.noteSelected.heartRate ||
        this.noteSelected.oxygenSaturation ||
        this.noteSelected.imc ||
        this.noteSelected.note ||
        this.noteSelected.subjective ||
        this.noteSelected.objective ||
        this.noteSelected.analysis ||
        this.noteSelected.plan ||
        this.noteSelected.diagnostics ||
        this.noteSelected.treatment ||
        this.noteSelected.laboratoryStudies
      );
    },
    computedDateFormatted() {
      return helperServices.DateService.formatDate(this.noteSelected.dateNote);
    },
  },
};
</script>
<style scoped>
#lateral .v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
</style>
