<template>
  <div id="app" v-bind:class="currentTheme">
    <v-toolbar flat class="context-toolbar">
      <v-toolbar-title class="context-title2">
        <h3>{{ headerTitle }}</h3>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon bottom right offset-y @click="closeWindow('cerrar', '')">
        <v-icon class="context-icon-color">close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="context-card2">
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="470px"
        color="transparent"
      >
        <v-container style="height: 470px">
          <div>
            <v-form ref="userSelected" @submit.prevent="submit">
              <v-container>
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="userSelected.userName"
                      :rules="rules.userName"
                      label="Nombre Completo"
                      required
                      class="mt-5 custom-placeholer-color"
                      append-icon="person"
                      outlined
                      dense
                      placeholder="Introduce el nombre del usuario asistente"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="userSelected.email"
                      :rules="rules.email"
                      class="mt-5 custom-placeholer-color"
                      label="Email"
                      required
                      outlined
                      dense
                      placeholder="Introduce el email"
                      append-icon="mail"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <div v-show="this.userSelected.id != -1">
                  <v-layout row wrap justify-space-around>
                    <v-flex xs12 md12>
                      <v-checkbox                       
                        v-model="userSelected.updatePassword"
                        label="Cambiar password"
                        @click="LimpiarPassword()"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap
                    justify-space-around
                    v-show="userSelected.updatePassword"
                  >
                    <v-flex xs12 md12>
                         <v-text-field
                            :append-icon="
                              show3 ? 'visibility' : 'visibility_off'
                            "
                            v-model="userSelected.password"
                            :rules="rules.password"
                            label="Password"
                            outlined
                            dense
                            required
                            :type="show3 ? 'text' : 'password'"
                              placeholder="Introduce el password"
                            class="mt-5 custom-placeholer-color"
                            @click:append="show3 = !show3"
                           
                          ></v-text-field>  
                    </v-flex>
                  </v-layout>
                </div>
                <div v-show="this.userSelected.id == -1">
                  <v-layout row wrap justify-space-around>
                    <v-flex xs12 md12>
                      <v-text-field
                            :append-icon="
                              show3 ? 'visibility' : 'visibility_off'
                            "
                            v-model="userSelected.password"
                            :rules="rules.password"
                            label="Nuevo password"
                            outlined
                            dense
                            required
                            :type="show3 ? 'text' : 'password'"
                              placeholder="Introduce el password"
                            class="mt-5 custom-placeholer-color"
                            @click:append="show3 = !show3"
                           
                          ></v-text-field>                     
                    </v-flex>
                  </v-layout>
                </div>

                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="userSelected.phone"
                      :rules="rules.phone"
                      class="mt-5 custom-placeholer-color"
                      label="Teléfono"
                      required
                      outlined
                      dense
                      append-icon="phone"
                      @input="enforcePhoneFormat()"
                      maxlength="16"
                      placeholder="(123) 456-7890"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="userSelected.specialty"
                      class="mt-5 custom-placeholer-color"
                      label="Cargo"
                      required
                      outlined
                      dense
                      :rules="rules.specialty"
                      placeholder="Introduce el cargo asignado"
                      append-icon="contact_page"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                
              </v-container>

              <v-card-actions>
                <!-- <v-btn text @click.stop="show = false"> Cancelar </v-btn>-->
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!formIsValid"
                  :loading="isLoading"
                  type="submit"
                  class="context-button-style"
                  rounded
                  small
                >
                  <v-icon left> save </v-icon>
                  {{ buttonTitle }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </div>
        </v-container>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";

export default {
  data() {
    const defaultForm = Object.freeze({
      id: -1,
      userName: "",
      password: "",
      email: "",
      phone: "",
      specialty: "",
      updatePassword: false,
    });

    return {
      form: Object.assign({}, defaultForm),
      rules: {
        userName: [(val) => (val || "").length > 0 || "Nombre es requerido."],
        password: [
          (val) => (val || "").length > 0 || "Password es requerido.",
        ],
        phone: [(val) => (val || "").length > 0 || "Teléfono requerido."],
        email: [
          // (val) => (val || "").length > 0 || "Email es requerido.",
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Email invalido.";
          },
        ],
      },
      snackbar: false,
      defaultForm,
      isLoading: false,
      currentTheme: localStorage.getItem("theme-color"),
      show3: false,
    };
  },
  props: {
    userSelected: null,
    headerTitle: String,
    buttonTitle: String,
    role:String
  },
  computed: {
    formIsValid() {
      if (this.userSelected.updatePassword) {
        return (
          this.userSelected.userName &&
          this.userSelected.password &&
          this.userSelected.phone &&
          this.userSelected.email 
        );
      }
      else{
        return (
          this.userSelected.userName &&        
          this.userSelected.phone &&
          this.userSelected.email 
        );
      }
    },
  },
  mounted() {
    //  this.isVisible = this.props.patientSelected.isVisible;
    // console.log(this.props.patientSelected);
    // this.patientSelected = this.props.patientSelected;
  },

  methods: {
    resetForm() {
      this.userSelected = Object.assign({}, this.defaultForm);
      this.$refs.userSelected.reset();
    },

    LimpiarPassword() {
      this.userSelected.password = "";
    },

    submit() {
      this.isLoading = true;
      let item = {
        id: 0,
        userName: this.userSelected.userName,
        password: this.userSelected.password,
        email:
          this.userSelected.email != undefined ? this.userSelected.email : "",
        phone: this.userSelected.phone,
        specialty:
          this.userSelected.specialty != undefined
            ? this.userSelected.specialty
            : "",
        updatePassword: this.userSelected.updatePassword,
      };
      //console.log(item);
      if (this.userSelected.id == -1) {
        //Se inserta un nuevo registro
        apiServices.userService
          .InsertConsumerUser(item)
          .then(() => {
            //this.resetForm();
            // this.$router.push({
            // name: "Patients",
            //  params: { isNewPatient: true },
            // });
            // console.log(item);
            this.closeWindow("nuevo", "");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });

        // item["fechaCreacion"] = new Date();

        //db.collection("patients").add(item);
      } else {
        item["id"] = this.userSelected.id;
        apiServices.userService
          .UpdateConsumerUser(item)
          .then(() => {
            //this.resetForm();
            // this.$router.push({
            //   name: "Patients",
            //   params: { isNewPatient: false },
            // });
            this.closeWindow("editar", item);
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
        //item["fechaActualizacion"] = new Date();
        //  db.collection("patients")
        //    .doc(this.id)
        //    .update(item);
      }
    },
    closeWindow(value, item) {
      if (value == "nuevo") {
        let result = {
          isVisible: false,
          tipo: "nuevo",
        };
        this.$emit("closeEditUser", result);
      } else if (value == "editar") {
        let result = {
          isVisible: false,
          tipo: "editar",
          item: item,
        };
        this.$emit("closeEditUser", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEditUser", result);
      }
      //  this.resetForm();
    },
    enforcePhoneFormat() {
      let x = this.userSelected.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.userSelected.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
  created() {
    // let itemUpdate = this.$route.params.patient; //recupero el objeto del router
    //this.useruid = this.$store.getters.user.data.uid; //recupero el userUID
    /* if (this.patientSelected != undefined) {
      // console.log("recupero actualizar " + itemUpdate);
      //editar
      this.headerTitle = "Editar Paciente";
      this.buttonTitle = "Actualizar";
      this.id = this.patientSelected.id;
      this.idPatient = this.patientSelected.id;
      this.form.name = this.patientSelected.name;
      this.form.lastName = this.patientSelected.lastName;
      this.form.birthDate = this.patientSelected.birthDate;
      this.form.address = this.patientSelected.address;
      this.form.phone = this.patientSelected.phone;
      this.form.email = this.patientSelected.email;
      this.form.rfc = this.patientSelected.rfc;
      this.form.typeAppointment = this.patientSelected.typeAppointment;
      this.form.patientState = this.patientSelected.patientState;
    } else {
      // let uid = this.$route.params.useruid; //recupero el objeto del router
      //console.log("recupero guardar " + uid);
      //if (uid != undefined) this.useruid = uid;
      this.headerTitle = "Agregar Paciente";
      this.buttonTitle = "Guardar";
    }*/
  },
};
</script>
