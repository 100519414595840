<template>
  <v-card class="mx-2 my-2" elevation="24" color="green">
    <!-- <v-img height="250" src="../assets/agenda2.jpg"></v-img>-->

    <v-card-title>Realizar pago</v-card-title>

    <!--<form id="form-checkout">
      <input type="text" name="cardNumber" id="form-checkout__cardNumber" />
      <input
        type="text"
        name="cardExpirationDate"
        id="form-checkout__cardExpirationDate"
      />
      <input
        type="text"
        name="cardholderName"
        id="form-checkout__cardholderName"
      />
      <input
        type="email"
        name="cardholderEmail"
        id="form-checkout__cardholderEmail"
      />
      <input type="text" name="securityCode" id="form-checkout__securityCode" />
      <select name="issuer" id="form-checkout__issuer"></select>
      <input
        type="text"
        name="identificationNumber"
        id="form-checkout__identificationNumber"
      />
      <select name="installments" id="form-checkout__installments"></select>
      <button type="submit" id="form-checkout__submit">Pagar</button>
      <progress value="0" class="progress-bar">Cargando...</progress>
    </form>-->
  </v-card>
</template>

<script>
//const mercadopago = require("mercadopago");
//public-key

export default {
  data() {
    return {};
  },
  mounted() {
    // let plugin = document.createElement("script");
    // plugin.setAttribute("src", "https://sdk.mercadopago.com/js/v2");
    // plugin.async = true;
    // document.head.appendChild(plugin);
    //console.log(document.getElementsByTagName("head")[0].appendChild(plugin));
    // const mercadopago = require("mercadopago");
    //  mercadopago.configure({
    //    access_token: "APP_USR-cc0be3a3-d986-40b1-9d2e-ba332bff7d7e",
    //  });
  },
  async created() {},

  methods: {
    /*async createToken() {
      try {
        const token = await mercadopago.createCardToken({
          cardId: document.getElementById("cardId").value,
          securityCode: document.getElementById("cvv").value,
        });

        // Use the received token to make a POST request to your backend
        console.log("token received: ", token.id);
      } catch (e) {
        console.error("error creating token: ", e);
      }
    },*/
  },
};
</script>
