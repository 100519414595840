<template>
  <div id="app" v-bind:class="currentTheme">
    <v-toolbar flat class="context-toolbar">
      <v-toolbar-title class="context-title2">
        <h3>Imprimir consultas</h3>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon right top offset-y @click="closeWindow('cerrarPrintEvents')">
        <v-icon class="context-icon-color">close</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="context-card2">
      <v-card-subtitle
        ><h6>Selecciona el día que deseas imprimir</h6>
      </v-card-subtitle>
      <v-layout row wrap justify-space-around>
        <v-flex xs10 md10>
               <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Fecha"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    class="custom-placeholer-color"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectDateReport"
                  no-title                  
                  @input="menu2 = false"                  
                ></v-date-picker>
              </v-menu>
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="context-button-style" dark rounded @click="printer()" small>
          <v-icon left>print</v-icon> Imprimir
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>
<script>
import helperServices from "../Services/helperServices";

export default {
  data() {
    return {
      selectDateReport: "",
      menu2: false,
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  props: {
    events: null,
  },

computed:{
 computedDateFormatted() {
      return helperServices.DateService.formatDate(this.selectDateReport);
    },
},
  methods: {
    printer() {
      //parametros para imprimir
      var titleDoc = "Reporte Agenda de pacientes"; //titulo de la pestaña, nuevo documento
      var titleReport = "Agenda del día"; //titulo del reporte
      //datos para imprimir
      var dataSelectedToPrint = this.events.filter(
        (item) =>
          item.appointmentDate === this.selectDateReport &&
          item.scheduleState != "Cancelada"
      );
      //orden de las columnas, debe hacer match con los nombres de la coleccion de datos
      var columnsDisplayOrderArray = [
        "horario",
        "name",
        "address",
        "phone",
        "scheduleState",
        "appointmentPlace",
        // "factura",
        // "tarifa",
        // "tarifaConFactura",
      ];
      //tamaño de las columnas, en el mismo orden que columnsDisplayOrderArray
      var customColumnsWidthsArray = [
        "auto",
        "auto",
        "auto",
        "auto",
        "auto",
        "auto",
        //  "auto",
        //  "auto",
        //  "auto",
      ];
      //orden de las columnas de la cabecera de la tabla a imprimir
      var customHeadersArrays = [
        {
          text: "Horario",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Nombre",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Dirección",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Teléfono",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Estado",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Lugar",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
      ];
      var orientationDescription = "portrait";
      var pageSizeDescription = "A4";
      // itemsSelected = itemsSelectedfromDay.sort((a, b) => {
      //   return b.start - a.start;
      //  });

      //se llama al metodo imprimir
      helperServices.PrintService.printer(
        titleDoc,
        titleReport,
        this.selectDateReport,
        dataSelectedToPrint,
        columnsDisplayOrderArray,
        customColumnsWidthsArray,
        customHeadersArrays,
        orientationDescription,
        pageSizeDescription
      );
      this.closeWindow("cerrarPrintEvents");
    },
    closeWindow(value) {
      if (value == "cerrarPrintEvents") {
        let result = {
          isVisible: false,
          tipo: "cerrarPrintEvents",
        };
        this.$emit("closeEventDetail", result);
      }
    },
  },
  created() {
    this.selectDateReport = new Date().toISOString().substr(0, 10);
  },
};
</script>
