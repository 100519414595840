import store from '../store/index'

class ScheduleDisabledService {
    axios
    baseUrl

    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}schedulesDisabled`
    }

    GetScheduleDisabledById(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetScheduleDisabledById/?id=${id}`);
    }    

    GetSchedulesDisabled() {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetSchedulesDisabled`);
    }

    GetSchedulesDisabledFullDayList() {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetSchedulesDisabledFullDayList`);
    }

    GetSchedulesDisabledFilter(filter) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/GetSchedulesDisabled`,filter);
    }

    InsertScheduleDisabled(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/InsertScheduleDisabled`, model);
    }

    UpdateScheduleDisabled(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdateScheduleDisabled`, model);
    }

    DeleteScheduleDisabled(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/DeleteScheduleDisabled/${id}`);
    }
}

export default ScheduleDisabledService