<template ref="refToElement">
  <div id="app" v-bind:class="currentTheme">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <div class="context-title">
      <h1>Pacientes</h1>
    </div>
    <div class="context-card">
      <v-layout row align-end>
     <v-spacer></v-spacer>    
          <v-btn
            rounded
            dark
            @click="addItem()"
            class="context-button-style mr-1"
            small
          >
            <v-icon left>add</v-icon>
            Nuevo
          </v-btn>

          <v-btn
            rounded
            dark
            @click="printer()"
            class="context-button-style mr-5"
            small
          >
            <v-icon left>print</v-icon>
            Imprimir
          </v-btn>
        <!--<template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="addItem()"                
                class="context-icon-color mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>Nuevo Paciente</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="printer()"               
                class="context-icon-color mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>print</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
        </template>-->
      </v-layout>
      <v-data-table
        :dark="isDark"
        :headers="headers"
        :items="patients"
        :server-items-length="totalPatients"
        :options.sync="options"
        sort-by="fullName"
        class="row-pointer mt-5 context-table row-textcolor-table hoverTable"
        :search="search"
        @click:row="goPatientDetail"
        @update:options="getPatients"
        fixed-header
      
      >
        <!--
        header custoizado
        <template #header="{ props: { headers } }">
    <thead class="v-data-table-header">
      <tr>
        <th
          v-for="header in headers"
          :key="header.value"
          class="text-uppercase"
          scope="col"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
  </template>
       -->
        <template v-slot:top>
          <v-toolbar flat class="context-toolbar">
            <!-- <v-toolbar-title>Lista de pacientes</v-toolbar-title>
             <v-divider class="mx-4" inset vertical></v-divider>
                <v-card-subtitle>Lista de pacientes</v-card-subtitle> -->
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Buscar paciente"
              single-line
              hide-details
              class="mt-5 mb-8 custom-placeholer-color"
              full-width
              dense
              flat
              outlined
              rounded
              placeholder="Escribe para filtrar la lista de pacientes"
              @change="getPatients"
            ></v-text-field>
            <v-btn
              rounded
              icon
              x-large
              class="context-icon-color mt-5 ml-3 mb-8"
              @click="getPatients"
            >
              <v-icon>search</v-icon>
            </v-btn>
            <!-- <v-spacer></v-spacer> 
            <v-btn fab small dark color="primary" @click="addItem()">
              <v-icon>add</v-icon>
            </v-btn>-->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title>Eliminar</v-card-title>
                <v-card-subtitle
                  >Desea eliminar el paciente
                  {{ patientSelected.fullName }} ?</v-card-subtitle
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="error" @click="deleteItemConfirm"
                    >Eliminar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!--  <v-dialog v-model="dialogInfo" max-width="500px">
                <v-card>
                  <v-toolbar dark>
                    <v-toolbar-title>Información del paciente</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogInfo = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-title>{{ infoItem.fullName }}</v-card-title>
                  <v-card-text>
                    <div class="text-body-2">
                      <b> Fecha de nacimiento:</b> {{ infoItem.birthDate }}
                    </div>
                    <div class="text-body-2">
                      <b>Dirección:</b> {{ infoItem.address }}
                    </div>
                    <div class="text-body-2">
                      <b>Teléfono:</b> {{ infoItem.phone }}
                    </div>
                    <div v-if="infoItem.email" class="text-body-2">
                      <b>Email:</b> {{ infoItem.email }}
                    </div>
                    <div v-else class="text-body-2">
                      <b>Email:</b> Sin datos
                    </div>
                    <div v-if="infoItem.rfc" class="text-body-2">
                      <b>RFC:</b> {{ infoItem.rfc }}
                    </div>
                    <div v-else class="text-body-2"><b>RFC:</b> Sin datos</div>
                    <div v-if="infoItem.typeAppointment" class="text-body-2">
                      <b>Tipo Cita:</b> {{ infoItem.typeAppointment }}
                    </div>
                    <div v-else class="text-body-2">
                      <b>Tipo Cita:</b> Sin datos
                    </div>
                    <div v-if="infoItem.patientState" class="text-body-2">
                      <b>Estado:</b> {{ infoItem.patientState }}
                    </div>
                    <div v-else class="text-body-2">
                      <b>Estado:</b> Sin datos
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>-->
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <!--  <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  medium
                  class="mr-2"
                  color="green darken-2"
                  @click.stop="informacionItem(item)"
                >
                  info
                </v-icon>
              </template>
              <span>Información</span>
            </v-tooltip>-->
          <v-layout row align-center>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="context-icon-color mr-2"
                    @click.stop="editItem(item)"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="context-icon-color mr-2"
                    @click.stop="deleteItem(item)"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </v-col>
          </v-layout>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-snackbar v-model="snackbar" bottom>
      <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="AddPatientDialog"
      width="500px"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <EditPatient
        :patientSelected="patientSelected"
        :headerTitle="headerTitle"
        :buttonTitle="buttonTitle"
        @closeEditPatient="closeWinEditPatient"
      />
    </v-dialog>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
//import PatientStateEnum from '../enums/PatientStateEnum';
import EditPatient from "../components/EditPatient.vue";
import LoadingProcess from "../components/LoadingProcess.vue";

//https://sweetalert2.github.io/

export default {
  components: { EditPatient, LoadingProcess },

  data() {
    const defaultForm = Object.freeze({
      id: -1,
      name: "",
      lastName: "",
      birthDate: new Date().toISOString().substr(0, 10),
      address: "",
      phone: "",
      email: "",
      rfc: "",
      typeAppointment: "",
      patientState: "",
    });
    return {
      patientSelected: Object.assign({}, defaultForm),
      AddPatientDialog: false,
      headerTitle: "",
      buttonTitle: "",
      search: "",
      dialogDelete: false,
      // dialogInfo: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "fullName",
          class: "context-header",
        },
        { text: "Sexo", value: "sex", class: "context-header" },
        // { text: "Dirección", value: "address" },
        { text: "Teléfono", value: "phone", class: "context-header" },
        //{ text: "Email", value: "email" },
        // { text: "RFC", value: "rfc" },
        {
          text: "Tipo Paciente",
          value: "typeAppointment",
          class: "context-header",
        },
        { text: "Ficha", value: "numberSheet", class: "context-header" },
        { text: "Estado", value: "patientState", class: "context-header" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          class: "context-header",
        },
      ],
      patients: [],
      options: {},
      totalPatients: 0,
      itemsPerPage: 0,
      page: 0,
      snackbar: false,
      messageSnackbar: "",
      infoItem: {
        fullName: "",
        birthDate: "",
        address: "",
        phone: "",
        email: "",
        rfc: "",
        typeAppointment: "",
        patientState: "",
      },
      loadingShow: false,
      loadingTitle: "",
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
      isDark: false,
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getPatients;
      },
      deep: true,
    },
  },
  async created() {
    if (this.currentTheme == "theme-black") this.isDark = true;
    else this.isDark = false;
  },
  mounted() {
    //this.useruid = this.$store.getters.user.data.uid;
    this.loadingTitle = "Cargando lista de pacientes...";

    // this.getPatients();

    this.isMobile = helperServices.MobileService.isMobile();
    /* let isNewPatient = this.$route.params.isNewPatient;
    if (isNewPatient != undefined) {
      if (isNewPatient) {
        this.snackbar = true;
        this.messageSnackbar = "Paciente creado correctamente.";
        // this.showSnackbar = true;
      } else {
        this.snackbar = true;
        this.messageSnackbar = "Paciente modificado correctamente.";
        // this.showSnackbar = true;
      }
    }*/
  },

  methods: {
    initialize() {},

    goPatientDetail(value) {
      //this.$router.push("/").catch(() => {});
      //para mandar whatsapp
      // window.location.href = `https://api.whatsapp.com/send?phone=525541125362&text=Me%20gustaría%20saber%20el%20precio%20del%20sitio%20web`;
      this.$router.replace({
        name: "PatientSelected",
        params: { patientSelected: value },
      });
    },
    getPatients() {
      //const params = new URLSearchParams();
      // params.append("Id", "");
      //params.append("Name", "");
      //params.append("LastName", "");
      //params.append("FullName", "");
      //params.append("UserUID", this.useruid);
      //params.append("PageSize", "0");
      //params.append("PageNumber", "0");
      const { page, itemsPerPage } = this.options;

      // sortBy,sortDesc
      var filter = {
        searchText: this.search,
        userUID: null,
        PageSize: itemsPerPage,
        PageNumber: page,
      };
      //this.itemsPerPage = filter.PageSize;
      //this.page = filter.page;
      this.loadingShow = true;
      apiServices.patientService
        .GetPatientsPagination(filter)
        .then((resp) => {
          this.patients = resp.data.data;
          this.totalPatients = resp.data.meta.totalCount;
          //  this.currentPage = resp.data.meta.currentPage;
          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    addItem() {
      this.headerTitle = "Agregar paciente";
      this.buttonTitle = "Guardar";
      this.patientSelected = Object.assign({}, this.defaultForm);
      this.patientSelected.id = -1;
      this.AddPatientDialog = true;
      // this.$router.push({
      //   name: "EditPatient",
      //params: { useruid: this.useruid },
      //  });
    },
    editItem(item) {
      // this.$router.push({
      //   name: "EditPatient",
      //   params: { patient: item },
      // });
      this.headerTitle = "Editar paciente";
      this.buttonTitle = "Actualizar";
      this.patientSelected = item;
      this.AddPatientDialog = true;
    },

    deleteItem(item) {
      this.patientSelected = item;
      //this.dialogDelete = true;
      this.$fire({
        title: "Desea eliminar el paciente?",
        text: item.fullName,
        type: "question",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          this.deleteItemConfirm();
          this.$fire({
            position: "top-end",
            title: "Paciente eliminado correctamente!",
            // text: "El registro fue eliminado de la base de datos",
            type: "success",
            timer: 3000,
            toast: true,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });
        }
      });
    },

    deleteItemConfirm() {
      if (this.patientSelected) {
        apiServices.patientService
          .DeletePatient(this.patientSelected.id)
          .then(() => {
            this.search = "";
            //this.getPatients;
            this.getPatients();
            // this.options = "getPatients"

            // this.snackbar = true;
            // this.messageSnackbar = "Paciente eliminado correctamente.";
          })
          .catch((error) => {
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    /*   informacionItem(item) {
      this.infoItem = Object.assign({}, item);
      this.dialogInfo = true;
    },*/
    closeWinEditPatient(result) {
      if (result.tipo == "cerrar") {
        this.AddPatientDialog = result.isVisible;
      } else if (result.tipo == "nuevo") {
        this.AddPatientDialog = result.isVisible;
        //this.snackbar = true;
        // this.messageSnackbar = "Paciente creado correctamente.";

        this.$fire({
          position: "top-end",
          title: "Paciente creado correctamente",
          // text: "Operación exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        this.getPatients();
        //  this.options="getPatients";
        // this.totalPatients += 1;
        // this.showSnackbar = true;
      } else if (result.tipo == "editar") {
        this.AddPatientDialog = result.isVisible;
        // this.snackbar = true;
        // this.messageSnackbar = "Paciente modificado correctamente.";
        this.getPatients();
        //https://www.npmjs.com/package/vue-simple-alert
        this.$fire({
          position: "top-end",
          title: "Paciente modificado correctamente",
          // text: "Edición exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          //grow:'row',
          //width: 300,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });

        //this.options = "getPatients";
        //console.log(result)
        //const indexOfItemInArray = this.patients.findIndex(q => q.id === result.item.id);
        //this.patients.splice(indexOfItemInArray, 1, result.item);
        // this.options.handler.getPatients;
        // this.showSnackbar = true;
      }
    },
    printer() {
      //parametros para imprimir
      var titleDoc = "Reporte Agenda de pacientes"; //titulo de la pestaña, nuevo documento
      var titleReport = "Lista de pacientes"; //titulo del reporte
      //datos para imprimir
      var dataSelectedToPrint = this.patients;
      //orden de las columnas, debe hacer match con los nombres de la coleccion de datos
      var columnsDisplayOrderArray = [
        "fullName",
        "address",
        "email",
        "phone",
        "rfc",
        "patientState",
        "typeAppointment",
      ];
      //tamaño de las columnas, en el mismo orden que columnsDisplayOrderArray
      var customColumnsWidthsArray = [
        "auto",
        "auto",
        "auto",
        "auto",
        "auto",
        "auto",
        "auto",
      ];
      //orden de las columnas de la cabecera de la tabla a imprimir
      var customHeadersArrays = [
        {
          text: "Nombre",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Dirección",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Email",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Teléfono",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "RFC",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Estado",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Tipo",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
      ];
      var orientationDescription = "Landscape"; // Portrait or Landscape.
      var pageSizeDescription = "A4";
      // itemsSelected = itemsSelectedfromDay.sort((a, b) => {
      //   return b.start - a.start;
      //  });

      //se llama al metodo imprimir
      helperServices.PrintService.printer(
        titleDoc,
        titleReport,
        new Date().toISOString().substr(0, 10),
        dataSelectedToPrint,
        columnsDisplayOrderArray,
        customColumnsWidthsArray,
        customHeadersArrays,
        orientationDescription,
        pageSizeDescription
      );
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
/**Cambia tamaño de letra en header */
.v-data-table::v-deep th {
  font-size: 16px !important;
}
/**Cambia tamaño de letra en rows */
/*.v-data-table::v-deep td {
  font-size: 12px !important;
}*/
</style>
