<template>
  <div class="SchedulesByPatient">
  
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <v-data-table
      :dark="isDark"
      :headers="headers"
      :items="schedules"
      class="row-pointer context-table row-textcolor-table hoverTable"
      fixed-header
      height="400"
    >
      <template v-slot:[`item.statusColor`]="{ item }">
        <v-avatar :color="item.statusColor" size="30"></v-avatar>
      </template>
      <template v-slot:no-data>
        <v-alert
          prominent
          border="left"
          colored-border
          elevation="4"
          dense
          text
          color="yellow lighten-2"
          type="warning"
          class="context-card2 mt-2"
        >
          <h6>El paciente no tiene consultas registradas.</h6>
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import helperServices from "../Services/helperServices";
import apiServices from "../Services/apiServices";
import LoadingProcess from "../components/LoadingProcess.vue";

export default {
  components: { LoadingProcess },
  data() {
    return {
      isMobile: false,
      schedules: [],
      options: {},
      totalSchedules: 0,
      //itemsPerPage: 0,
      //page: 0,
      snackbar: false,
      messageSnackbar: "",
      isLoading: false,

      headers: [
        /* {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "patient.fullName",
          class: "context-header",
        },*/
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "statusColor",
          class: "context-header",
        },
        {
          text: "Fecha",
          value: "appointmentDate",
          class: "context-header",
        },
        {
          text: "Tipo Paciente",
          value: "patient.typeAppointment",
          class: "context-header",
        },

        { text: "Hora", value: "start", class: "context-header" },
        { text: "Lugar", value: "appointmentPlace", class: "context-header" },
      ],
      isDark: false,
      loadingShow: false,
      loadingTitle: "",
    };
  },
  props: {
    patientSelected: null,
  },

  methods: {
    initialize() {},
    getSchedules() {
      //const params = new URLSearchParams();
      // params.append("Id", "");
      //params.append("Name", "");
      //params.append("LastName", "");
      //params.append("FullName", "");
      //params.append("UserUID", this.useruid);
      //params.append("PageSize", "0");
      //params.append("PageNumber", "0");
      //const { page, itemsPerPage } = this.options;

      //this.itemsPerPage = filter.PageSize;
      //this.page = filter.page;
      this.loadingShow = true;
      apiServices.scheduleService
        .GetScheduleByPatient(this.patientSelected.id)
        .then((resp) => {
          this.schedules = resp.data;

          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
  },
  created() {
    if (this.currentTheme == "theme-black") this.isDark = true;
    else this.isDark = false;

    this.getSchedules();
  },
  mounted() {
    this.isMobile = helperServices.MobileService.isMobile();
    this.loadingTitle = "Cargando consultas registradas...";
  },
  computed: {},
};
</script>
<style scoped>
#lateral .v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
</style>
