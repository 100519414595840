<template>
  <div>
    <div class="context-card">
      <v-toolbar flat class="context-toolbar">
        <v-toolbar-title>
          <div v-if="!isMobile">
            <h3>{{ infoSelected.title }}</h3>
          </div>
          <div v-else>
            <h5>{{ infoSelected.title }}</h5>
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <v-item-group mandatory class="mt-5">
        <v-row justify="space-between" align="center">
          <v-col
            cols="12"
            md="6"
            v-for="dash in infoSelected.items"
            :key="dash.name"
          >
            <v-item v-slot="{ toggle }">
              <div
                :color="dash.color"
                class="context-card2"
                max-width="500"
                @click="toggle"
                elevation="10"
                outlined
                dark
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <h6>{{ dash.name }}</h6>
                    <h1>{{ dash.total }}</h1>
                    <!--    <v-list-item-subtitle
                      >Greyhound divisely hello coldly
                      fonwderfully</v-list-item-subtitle
                    >-->
                  </v-list-item-content>
                  <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                    class="rounded-xl"
                  >
                    <v-icon large :color="dash.color">{{ dash.icon }}</v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </div>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </div>
  </div>
</template>
<script>
import helperServices from "/src/Services/helperServices";
export default {
  name: "DashboardTipoPaciente",
  data: () => {
    return {
      toggle_exclusive: 1,
      isMobile: false,
    };
  },
  props: {
    infoSelected: null,
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
<style>
.space {
  margin-left: -100px;
  margin-right: -100px;
}
</style>
