<template>
  <div id="app" v-bind:class="currentTheme">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <div class="context-title">
      <h1>Compras</h1>
    </div>
    <div class="context-card">
      <div v-if="user != null">
        <v-form
          ref="user"
          class="mx-4"
          v-if="user.dataPaypal.typePayment !== null"
        >
          <template v-if="user.dataPaypal.typePayment === 'SUBSCRIPTION'">
            <div>
              <v-card-title class="text-center">
                <v-spacer />
                <v-alert type="info" dense> Suscripción activa </v-alert>
                <!--  <v-chip
                color="blue accent-1"
                class="font-weight-bold"
                text-color="white"
              >
                Suscripción activa</v-chip
              >-->
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-text-field
                        v-model="user.dataPaypal.status"
                        label="Estado de la suscripción"
                        filled
                        shaped
                        dense
                        readonly
                        class="input-group--focused"
                        append-icon="article"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-text-field
                        v-model="user.dataPaypal.operationId"
                        label="Suscripción Id"
                        filled
                        shaped
                        dense
                        readonly
                        class="input-group--focused"
                        append-icon="assignment"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-text-field
                        v-model="user.dataPaypal.planId"
                        label="Plan Id"
                        filled
                        shaped
                        dense
                        readonly
                        class="input-group--focused"
                        append-icon="payment"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-text-field
                        v-model="user.dataPaypal.lastPaymentTime"
                        label="Ultimo pago"
                        filled
                        shaped
                        dense
                        readonly
                        class="input-group--focused"
                        append-icon="receipt"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-text-field
                        v-model="dateApprovedPayment"
                        label="Fecha de aprobacion del pago"
                        filled
                        shaped
                        dense
                        readonly
                        class="input-group--focused"
                        append-icon="event"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-text-field
                        v-model="nextPaymentDate"
                        label="Fecha del siguiente pago"
                        filled
                        shaped
                        dense
                        readonly
                        class="input-group--focused"
                        append-icon="event"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="ma-2 white--text font-weight-bold"
                  color="red darken-1"
                >
                  Cancelar Suscripción
                </v-btn>
              </v-card-actions>
            </div>
          </template>
          <template v-else-if="user.dataPaypal.typePayment === 'PAYMENT'">
            <div>
              <v-card-title class="text-center">
                <v-spacer />
                <v-alert type="info" dense> Pago vigente </v-alert>
                <!--  <v-chip
                color="blue accent-1"
                class="font-weight-bold"
                text-color="white"
              >
                Suscripción activa</v-chip
              >-->
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-alert
                        prominent
                        border="left"
                        colored-border
                        color="orange accent-4"
                        elevation="4"
                        icon="article"
                        dense
                        class="mx-auto rounded-xl"
                      >
                        <div class="text-h6">Pago</div>
                        <div>{{ user.dataPaypal.status }}</div>
                      </v-alert>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-alert
                        prominent
                        border="left"
                        colored-border
                        color="grey accent-4"
                        elevation="4"
                        icon="assignment"
                        dense
                        class="mx-auto rounded-xl"
                      >
                        <div class="text-h6">Id de pago</div>
                        <div>{{ user.dataPaypal.operationId }}</div>
                      </v-alert>
                    </v-flex>
                  </v-layout>
                  <!--<v-layout row wrap justify-space-around>
                      <v-flex xs11 md12>
                        <v-text-field
                          v-model="user.dataPaypal.planId"
                          label="Plan Id"
                          filled
                          shaped
                          dense
                          readonly
                          class="input-group--focused"
                          append-icon="payment"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>-->
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-alert
                        prominent
                        border="left"
                        colored-border
                        color="green accent-2"
                        elevation="4"
                        icon="receipt"
                        dense
                        class="mx-auto rounded-xl"
                      >
                        <div class="text-h6">Ultimo pago</div>
                        <div>{{ dateApprovedPayment }}</div>
                      </v-alert>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-alert
                        prominent
                        border="left"
                        colored-border
                        color="green accent-4"
                        elevation="4"
                        icon="event"
                        dense
                        class="mx-auto rounded-xl"
                      >
                        <div class="text-h6">Fecha de aprobación del pago</div>
                        <div>{{ dateApprovedPayment }}</div>
                      </v-alert>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-alert
                        prominent
                        border="left"
                        colored-border
                        color="red accent-4"
                        elevation="4"
                        icon="event"
                        dense
                        class="mx-auto rounded-xl"
                      >
                        <div class="text-h6">Fecha de vencimiento</div>
                        <div>{{ nextPaymentDate }}</div>
                      </v-alert>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap justify-space-around>
                    <v-flex xs11 md12>
                      <v-alert
                        prominent
                        border="left"
                        colored-border
                        color="blue accent-4"
                        elevation="4"
                        icon="more_time"
                        dense
                        class="mx-auto rounded-xl"
                      >
                        <div class="text-h6">Días restantes</div>
                        <div>{{ endPaymentDays }}</div>
                      </v-alert>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="ma-2 white--text font-weight-bold"
                  @click="goPaymentCatalog()"
                  color="blue darken-1"
                >
                  Ir al catálogo de compras
                </v-btn>
              </v-card-actions>
            </div>
          </template>
        </v-form>
        <v-form ref="user" class="mx-0" v-else-if="user.trialPeriod">
          <v-card-title class="text-center">
            <v-spacer />
            <v-alert type="warning" dense class="mx-auto rounded-xl">
              Periodo de prueba
            </v-alert>
            <!--<v-chip color="amber accent-1" class="font-weight-bold">
                Preriodo de prueba</v-chip
              >-->
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-layout row wrap justify-space-around>
                <v-flex xs12 md12>
                  <v-alert
                    prominent
                    color="red accent-4"
                    dark
                    elevation="4"
                    icon="event"
                    dense
                    class="mx-auto rounded-xl"
                  >
                    <div class="text-h6">Fecha de vencimiento</div>
                    <div>{{ endTrailPeriodDate }}</div>
                  </v-alert>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs12 md12>
                  <v-alert
                    prominent
                    color="green accent-4"
                    dark
                    elevation="4"
                    icon="more_time"
                    dense
                    class="mx-auto rounded-xl"
                  >
                    <div class="text-h6">Días restantes</div>
                    <div>{{ endTrailPeriodDays }}</div>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="white--text font-weight-bold"
              @click="goPaymentCatalog()"
              color="blue darken-1"
            >
              Catálogo de compras
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </div>
    <!--  <v-dialog
      v-model="ActiveSuscriptionDialog"
      width="600"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <ActiveSuscription
        @closeWinActiveSuscription="closeWinActiveSuscription"
      />
    </v-dialog>-->
  </div>
</template>

<script>
//import ActiveSuscription from "../components/ActiveSuscription.vue";
import helperServices from "../Services/helperServices";
import LoadingProcess from "../components/LoadingProcess.vue";
//import PaypalPayment from "../components/paypalPayment.vue";
//import apiServices from "../Services/apiServices";
import moment from "moment";
//var mercadopago = require("mercadopago");

export default {
  components: { LoadingProcess },
  data() {
    return {
      user: {},
      isMobile: false,
      loadingShow: false,
      loadingTitle: "",
      ActiveSuscriptionDialog: false,
      endTrailPeriodDays: "",
      endPaymentDays: "",
      endTrailPeriodDate: "",
      dateApprovedPayment: "",
      nextPaymentDate: "",
      currentTheme: localStorage.getItem("theme-color"),
    };
  },

  async created() {
    this.loadingTitle = "Cargando estado de la suscripción...";
    this.loadingShow = true;
    this.user = this.$store.getters.user;

    this.isMobile = helperServices.MobileService.isMobile();
    this.endTrailPeriodDays = this.getNumberOfDays(
      new Date(),
      this.user.trialPeriod.endTrailPeriodDate
    );

    this.endTrailPeriodDate = moment(
      String(this.user.trialPeriod.endTrailPeriodDate)
    ).format("DD/MM/YYYY hh:mm");

    if (this.user.dataPaypal.lastPaymentTime) {
      this.dateApprovedPayment = moment(
        String(this.user.dataPaypal.lastPaymentTime)
      ).format("DD/MM/YYYY hh:mm");
    }
    if (this.user.dataPaypal.typePayment === "PAYMENT") {
      //fecha de vencimiento
      // var paymentTime = this.user.dataPaypal.lastPaymentTime;
      // var futureMonth = moment(paymentTime).add(
      //   this.user.dataPaypal.monthsPaid,
      //   "M"
      // );
      // this.nextPaymentDate = moment(String(futureMonth)).format(
      this.nextPaymentDate = moment(
        String(this.user.dataPaypal.nextBillingTime)
      ).format("DD/MM/YYYY hh:mm");
      //dias restantes
      // var fechaActual = this.user.dataPaypal.lastPaymentTime;
      // var diasRestantes = moment(fechaActual).add(
      //   this.user.dataPaypal.monthsPaid,
      //   "M"
      // );
      this.endPaymentDays = this.getNumberOfDays(
        new Date(),
        this.user.dataPaypal.nextBillingTime
      );
    } else if (this.user.dataPaypal.typePayment === "SUBSCRIPTION") {
      if (this.user.dataPaypal.nextBillingTime) {
        this.nextPaymentDate = moment(
          String(this.user.dataPaypal.nextBillingTime)
        ).format("DD/MM/YYYY hh:mm");
      }
    }
    this.loadingShow = false;
  },

  filters: {
    currency(val) {
      return `${val.toFixed(2)} MXN`;
    },
  },

  methods: {
    initialize() {},
    getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    },

    closeWinActiveSuscription(result) {
      if (result.tipo == "cerrar") {
        this.ActiveSuscriptionDialog = result.isVisible;
      }
    },
    goPaymentCatalog() {
      this.$router.replace({
        name: "PaymentCatalog",
      });
    },
  },
};
</script>
