
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//https://blog.sqreen.com/authentication-best-practices-vue/
//https://austingil.com/prevent-browser-refresh-url-changes-route-navigation-vue/
export default new Vuex.Store({
  
    state: {       
        user: {
            token: '',            
            loggedIn: false,   
            loggedInAll:false,      
            data: null,
            dataPaypal: null,
            trialPeriod: null,
            dataPaypalConfig:null
        }
      },
      getters: {
        user(state){
          //var userdata = JSON.parse( localStorage.getItem('userdata') )
         // if(userdata)
        //  state.user = userdata
          return state.user
        }
      },
      mutations: {
       // SET_TOKEN(state, value) {
       //     state.user = value;
       //   },
       // SET_LOGGED_IN(state, value) {
       //   state.user.loggedIn = value;
       // },
        SET_USER(state, user) {
          state.user = user;
         
        },
        SET_USERSUSCRIPTION(state, userSuscription) {
          state.user = userSuscription;
        }
      },
      actions: {
        fetchUser({ commit }, user) {              
          //commit("SET_LOGGED_IN", user !== null);

          let usr =  {
            token: null,
            loggedIn: false,
            loggedInAll:false,
            data: {
              displayName:null,
              email:null,
              uid: null,
              role: null,
              phone:null,
              specialty:null,
            },
            dataPaypal: {
              typePayment: null,
              monthsPaid: null,
              status: null,
              amount: null,
              currencyCode:null,
              description: null,
              operationId: null,
              planId: null,
              userEmail: null,
              lastPaymentTime: null,
              nextBillingTime: null,
              failedPaymentsCount:null
            },
            trialPeriod:{
              daysTrialPeriod: null,
              email: null,
              endTrailPeriodDate: null,
              startTrailPeriodDate: null,
              state: null,
              userName:null,
            },
            dataPaypalConfig:{
              clientIdSubscription:null,
          clientIdPayment:null,
          urlBase:null,
          planIdSubscription:null,
            }
          };

          if (user) {

            usr.token = user.token;
            usr.loggedIn= user.token.length ? true : false;
            usr.loggedInAll = user.loggedInAll;
            usr.data.displayName=user.data.displayName;
            usr.data.email=user.data.email;
            usr.data.uid= user.data.uid;
            usr.data.role= user.data.role;
            usr.data.phone=user.data.phone;
            usr.data.specialty=user.data.specialty;
            //paypal
            usr.dataPaypal.typePayment = user.dataPaypal != null ? user.dataPaypal.typePayment : null;
            usr.dataPaypal.monthsPaid = user.dataPaypal != null ? user.dataPaypal.monthsPaid : null;
            usr.dataPaypal.status= user.dataPaypal != null ? user.dataPaypal.status : null;
            usr.dataPaypal.amount= user.dataPaypal != null ? user.dataPaypal.amount : null;
            usr.dataPaypal.currencyCode= user.dataPaypal != null ? user.dataPaypal.currencyCode : null;
            usr.dataPaypal.description= user.dataPaypal != null ? user.dataPaypal.description : null;
            usr.dataPaypal.operationId= user.dataPaypal != null ? user.dataPaypal.operationId : null;
            usr.dataPaypal.planId= user.dataPaypal != null ? user.dataPaypal.planId : null;
            usr.dataPaypal.userEmail= user.dataPaypal != null ? user.dataPaypal.userEmail : null;
            usr.dataPaypal.lastPaymentTime= user.dataPaypal != null ? user.dataPaypal.lastPaymentTime : null;
            usr.dataPaypal.nextBillingTime= user.dataPaypal != null ? user.dataPaypal.nextBillingTime : null;
            usr.dataPaypal.failedPaymentsCount= user.dataPaypal != null ? user.dataPaypal.failedPaymentsCount : null;
           //periodo de prueba
            usr.trialPeriod.daysTrialPeriod = user.trialPeriod != null ? user.trialPeriod.daysTrialPeriod : null;
            usr.trialPeriod.email = user.trialPeriod != null ? user.trialPeriod.email : null;
            usr.trialPeriod.endTrailPeriodDate =  user.trialPeriod != null ? user.trialPeriod.endTrailPeriodDate : null;
            usr.trialPeriod.startTrailPeriodDate = user.trialPeriod != null ? user.trialPeriod.startTrailPeriodDate : null;
            usr.trialPeriod.state = user.trialPeriod != null ? user.trialPeriod.state : null;
            usr.trialPeriod.userName = user.trialPeriod != null ? user.trialPeriod.userName : null;
            //paypal config
            usr.dataPaypalConfig.clientIdSubscription = user.dataPaypalConfig != null ? user.dataPaypalConfig.clientIdSubscription : null;
            usr.dataPaypalConfig.clientIdPayment = user.dataPaypalConfig != null ? user.dataPaypalConfig.clientIdPayment : null;
            usr.dataPaypalConfig.urlBase = user.dataPaypalConfig != null ? user.dataPaypalConfig.urlBase : null;
            usr.dataPaypalConfig.planIdSubscription = user.dataPaypalConfig != null ? user.dataPaypalConfig.planIdSubscription : null;

            commit("SET_USER", usr);
            //localStorage.setItem('userdata', JSON.stringify(usr))
           
            /*commit("SET_USER", {
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
              role: user.role,
              phone: user.phone,
              specialty: user.specialty,
            });*/
          } else {
            commit("SET_USER", usr);
           // localStorage.removeItem('userdata')
          }
         
        },
        fetchUserSuscription({ commit }, userSuscription) {    
          let usr =  {
            token: null,
            loggedIn: false,
            loggedInAll:false,
            data: {
              displayName:null,
              email:null,
              uid: null,
              role: null,
              phone:null,
              specialty:null,
            },
            dataPaypal: {
              typePayment: null,
              monthsPaid: null,
              status: null,
              amount: null,
              currencyCode:null,
              description: null,
              operationId: null,
              planId: null,
              userEmail: null,
              lastPaymentTime: null,
              nextBillingTime: null,
              failedPaymentsCount:null
            },
            trialPeriod:{
              daysTrialPeriod: null,
              email: null,
              endTrailPeriodDate: null,
              startTrailPeriodDate: null,
              state: null,
              userName:null,
            },
            dataPaypalConfig:{
              clientIdSubscription:null,
          clientIdPayment:null,
          urlBase:null,
          planIdSubscription:null,
            }
          };                   
          if (userSuscription) {           
            usr.dataPaypal.typePayment = userSuscription.dataPaypal != null ? userSuscription.dataPaypal.typePayment : null;
            usr.dataPaypal.monthsPaid = userSuscription.dataPaypal != null ? userSuscription.dataPaypal.monthsPaid : null;
            usr.dataPaypal.status= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.status : null;
            usr.dataPaypal.amount= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.amount : null;
            usr.dataPaypal.currencyCode= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.currencyCode : null;
            usr.dataPaypal.description= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.description : null;
            usr.dataPaypal.operationId= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.operationId : null;
            usr.dataPaypal.planId= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.planId : null;
            usr.dataPaypal.userEmail= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.userEmail : null;
            usr.dataPaypal.lastPaymentTime= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.lastPaymentTime : null;
            usr.dataPaypal.nextBillingTime= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.nextBillingTime : null;
            usr.dataPaypal.failedPaymentsCount= userSuscription.dataPaypal != null ? userSuscription.dataPaypal.failedPaymentsCount : null;
             //paypal config
             usr.dataPaypalConfig.clientIdSubscription = userSuscription.dataPaypalConfig != null ? userSuscription.dataPaypalConfig.clientIdSubscription : null;
             usr.dataPaypalConfig.clientIdPayment = userSuscription.dataPaypalConfig != null ? userSuscription.dataPaypalConfig.clientIdPayment : null;
             usr.dataPaypalConfig.urlBase = userSuscription.dataPaypalConfig != null ? userSuscription.dataPaypalConfig.urlBase : null;
             usr.dataPaypalConfig.planIdSubscription = userSuscription.dataPaypalConfig != null ? userSuscription.dataPaypalConfig.planIdSubscription : null;
 
            commit("SET_USERSUSCRIPTION", usr);
          } else {
            commit("SET_USERSUSCRIPTION", usr);
          }
        },
       
     /*   fetchToken({ commit }, token) { 
          let usr =  {
            token: null,
            loggedIn: false,
            data: {
              displayName:null,
              email:null,
              uid: null,
              role: null,
              phone:null,
              specialty:null,
            },
            dataSuscription: {
              status: null,
              statusUpdateTime: null,
              suscriptionId: null,
              planId: null,
              lastPaymentAmount:null,
              lastPaymentTime: null,
              nextBillingTime: null,
              failedPaymentsCount: null
            }
          };          
            if (token) {
              usr.token = token;
                commit("SET_TOKEN", usr);
                //localStorage.setItem('user-token', token) // store the token in localstorage
              } else {
                commit("SET_TOKEN", usr);
                //localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
              }   
         
        }*/
      }
})