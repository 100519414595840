import store from '../store/index'

class PaypalInformationService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}paypalinformation`
    }
    
    GetPaypalInformation() {        
        let self = this;   
       
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetPaypalInformation`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }   

    InsertPaypalSubscriptionInformation(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/InsertPaypalSubscriptionInformation`, model);
    }

    InsertPaypalPaymentInformation(model) {
      let self = this;
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.post(`${self.baseUrl}/InsertPaypalPaymentInformation`, model);
  }
   
}

export default PaypalInformationService