<template>
  <div id="app" v-bind:class="currentTheme">
    <div class="context-title">
      <v-app-bar color="transparent" flat elevate-on-scroll>
        <v-btn
          class="context-button-color"
          v-if="user.loggedIn && user.loggedInAll"
          icon
          bottom
          right
          offset-y
          @click="$router.push('/shopping')"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>

        <h1>Planes</h1>
      </v-app-bar>
    </div>
    <div class="context-card">
      <div class="context-title"><h3>Selecciona la opción de pago</h3></div>
      <v-item-group row class="mt-5">
        <v-row justify="space-between" align="center">
          <v-col cols="12" md="4" v-for="(item, index) in items" :key="index">
            <v-item v-slot="{ active, toggle }">
              <v-card class="rounded-xl" @click="planSelected(item)">
                <v-card
                  elevation="10"
                  class="d-flex rounded-xl"
                  height="210"
                  :color="active ? 'orange darken-1' : 'white'"
                  @click="toggle"
                >
                  <v-card-text class="ml-4 mt-2">
                    <div
                      :class="
                        active ? 'white--text body-1' : 'primary--text body-1'
                      "
                    >
                      {{ item.title }}
                    </div>
                    <div
                      v-if="item.discount"
                      :class="
                        active ? 'white--text title ' : 'black--text title '
                      "
                    >
                      <div class="text-decoration-line-through">
                        <span>MXN</span> ${{ item.amount | toCurrency }}
                      </div>
                      <div>
                        <span>MXN</span> ${{ item.amountDiscount | toCurrency }}
                      </div>
                    </div>
                    <div
                      v-else
                      :class="
                        active ? 'white--text title' : 'black--text title'
                      "
                    >
                      <span>MXN</span> ${{ item.amount | toCurrency }}
                    </div>

                    <div v-if="item.discount">
                      <v-chip
                        color="green"
                        small
                        :class="
                          active
                            ? 'white--text subtitle'
                            : 'white--text subtitle'
                        "
                        >{{ item.titleDiscount }}</v-chip
                      >
                    </div>
                    <div
                      :class="
                        active ? 'white--text subtitle' : 'grey--text subtitle'
                      "
                    >
                      {{ item.text }}
                    </div>
                    <div v-if="active" class="text-center white--text">
                      <v-progress-circular
                        indeterminate
                        size="34"
                      ></v-progress-circular>
                    </div>
                  </v-card-text>

                  <v-btn
                    icon
                    dark
                    :class="
                      active ? 'white--text mt-2 mr-2' : 'grey--text mt-2 mr-2'
                    "
                  >
                    <v-icon>
                      {{
                        active
                          ? "radio_button_checked"
                          : "radio_button_unchecked"
                      }}
                    </v-icon>
                  </v-btn>
                </v-card>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaymentPaypal",

  data: function () {
    return {
      user: {},
      items: [
        {
          title: "3 meses",
          subtitle: "AgendaPacientes",
          amount: "750",
          titleDiscount: "",
          discount: "",
          amountDiscount: "",
          text: "Plan por 3 meses.",
          plan_id: "", // plan id
          monthsPaid: 3,
          currencyCode: "MXN",
        },
        {
          title: "6 meses",
          subtitle: "AgendaPacientes",
          amount: "1500",
          titleDiscount: "",
          discount: "",
          amountDiscount: "",
          text: "Plan por 6 meses.",
          plan_id: "", // plan id
          monthsPaid: 6,
          currencyCode: "MXN",
        },
        {
          title: "1 año",
          subtitle: "AgendaPacientes",
          amount: "3000",
          titleDiscount: "5% descuento",
          discount: "150",
          amountDiscount: "2850",
          text: "Plan por 1 año.",
          plan_id: "", // plan id
          monthsPaid: 12,
          currencyCode: "MXN",
        },
        {
          title: "2 años",
          subtitle: "AgendaPacientes",
          amount: "6000",
          titleDiscount: "10% descuento",
          discount: "600",
          amountDiscount: "5400",
          text: "Plan por 2 años.",
          plan_id: "", // plan id
          monthsPaid: 24,
          currencyCode: "MXN",
        },
        {
          title: "3 años",
          subtitle: "AgendaPacientes",
          amount: "9000",
          titleDiscount: "20% descuento",
          discount: "1800",
          amountDiscount: "7200",
          text: "Plan por 3 años.",
          plan_id: "", // plan id
          monthsPaid: 36,
          currencyCode: "MXN",
        },
        {
          title: "4 años",
          subtitle: "AgendaPacientes",
          amount: "12000",
          titleDiscount: "30% descuento",
          discount: "2400",
          amountDiscount: "9600",
          text: "Plan por 4 años.",
          plan_id: "", // plan id
          monthsPaid: 48,
          currencyCode: "MXN",
        },
        /* {
          title: "Suscripción mensual",
          subtitle: "AgendaPacientes",
          amount: "250",
          titleDiscount: "",
          discount: "",
          amountDiscount: "",
          text: "Plan por tiempo ilimitado, hasta cancelar.",
          plan_id: "", // plan id
          monthsPaid: 1,
          currencyCode: "MXN",
        },*/
      ],
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  created() {
    this.user = this.$store.getters.user;
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });
      return formatter.format(value);
    },
  },
  methods: {
    planSelected(item) {
      localStorage.setItem("planSelected", JSON.stringify(item));
      setTimeout(() => {
        this.$router.replace({
          name: "PaymentSelected",
          params: { planSelected: item },
        });
      }, 1000);
    },
  },
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
</style>
