import store from '../store/index'

class PatientService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}patients`
    }
    
    GetPatientById(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetPatientById/?id=${id}`);
    }

    GetPatients() {        
        let self = this;   
        
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetPatients`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }
    GetPatientsPagination(filter) {        
      let self = this;   
      
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.post(`${self.baseUrl}/GetPatients`,filter);
        /*{
          headers: {
            'Authorization': "Bearer " + token
          }
        }*/
  }
    /*getAll(params) {        
        let self = this;           
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token.token   
        return self.axios.get(`${self.baseUrl}`,{
                params
          });
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }
    }*/

    InsertPatient(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/InsertPatient`, model);
    }

    UpdatePatient(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdatePatient`, model);
    }

    DeletePatient(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/DeletePatient/${id}`);
    }
}

export default PatientService