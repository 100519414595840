<template>
  <div>
    <div class="context-card">
      <v-toolbar flat class="context-toolbar">
        <v-toolbar-title>
          <div v-if="!isMobile">
            <h3>{{ title }}</h3>
          </div>
          <div v-else>
            <h5>{{ title }}</h5>
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <div>
        <p>{{ subtitle }}</p>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
          class="context-card2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import helperServices from "/src/Services/helperServices";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    chartData: {},
    title: {
      default: "",
      type: String,
    },
    subtitle: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      isMobile: false,
      /*   chartData: {
        labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [308, 52, 516, 10],
          },
        ],
      },*/
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
