import store from '../store/index'

class NoteService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}notes`
    }
    
    GetNoteById(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetNoteById/?id=${id}`);
    }

    GetNotesByPatient(id) {      
      let self = this;
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.get(`${self.baseUrl}/GetNotesByPatient/?patientId=${id}`);
  }
    GetNotes() {        
        let self = this;           
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetNotes`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }
    /*getAll(params) {        
        let self = this;           
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token.token   
        return self.axios.get(`${self.baseUrl}`,{
                params
          });
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }
    }*/

    InsertNote(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/InsertNote`, model);
    }

    UpdateNote(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdateNote`, model);
    }

    DeleteNote(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/DeleteNote/${id}`);
    }
}

export default NoteService