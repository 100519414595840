import store from '../store/index'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

class PrintService {

    printer(titleDoc, titleReport, selectedDate, dataSelectedToPrint, columnsDisplayOrderArray, customColumnsWidthsArray, customHeadersArrays, orientationDescription = "portrait",  pageSizeDescription = "A4") {
        //https://github.com/bpampuch/pdfmake/issues/24              
      
        var docDefinition = {
          info: {
            title: titleDoc,            
          },
          header: {
            columns: [
              {
                text: store.getters.user.data.displayName,
                alignment: "left",
                margin: [20, 15, 10, 0],
              },
              {
                text: "Fecha: " + this.getHourReport(selectedDate),
                alignment: "right",
                margin: [0, 15, 10, 20],
              },
            ],
          },
          pageOrientation: orientationDescription,
          pageSize: pageSizeDescription,
          compress: true,
          // permissions: {
          //   printing: "highResolution", //'lowResolution'
          //   modifying: false,
          //    copying: false,
          //   annotating: true,
          //   fillingForms: true,
          //   contentAccessibility: true,
          //   documentAssembly: true,
          // },
          content: [
            { text: titleReport, style: "header" },
  
            this.table(
              // External data
              dataSelectedToPrint,
              // Columns display order
              columnsDisplayOrderArray,
              // Custom columns widths
              customColumnsWidthsArray,
              // Show headers?
              true,
              // Custom headers
              customHeadersArrays,
              // Custom layout, use '' for no layout
              //""
              "lightHorizontalLines",
              //style
              "tableExample"
            ),
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
              alignment: "center",
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
          },
          defaultStyle: {
            // alignment: 'justify'
          },
        };
  
        //con estas lineas aparece un viewer por default de pdfmake solo que al descargar el archivo es mas pesado
        //pdfMake.createPdf(docDefinition).download();
        pdfMake.createPdf(docDefinition).open();
        //pdfMake.createPdf(docDefinition).open({}, window);
      }

      table(data, columns, witdhsDef, showHeaders, headers, layoutDef, styledef) {
        return {
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: witdhsDef,
            body: this.buildTableBody(data, columns, showHeaders, headers),
            style: styledef,
            // alignment: "center",
          },
          layout: layoutDef,
        };
      }
    
     buildTableBody(data, columns, showHeaders, headers) {
        var body = [];
        // Inserting headers
        if (showHeaders) {
          body.push(headers);
        }
  
        // Inserting items from external data array
        data.forEach(function (row) {
          var dataRow = [];
          // var i = 0;
  
          columns.forEach(function (column) {
            //console.log(column);
            //   if (column == "tarifa") {
            //     dataRow.push({
            //       text: formatMoney(Object.byString(row, column), ",", "."),
            //alignment: "center",
            // alignment: headers[i].alignmentChild,
            //     });
            //   } else if (column == "factura") {
            //     let itemFactura = "";
            //     if (Object.byString(row, column) === true) itemFactura = "Si";
            //     else itemFactura = "No";
  
            //   dataRow.push({
            //     text: itemFactura,
            //alignment: "center",
            // alignment: headers[i].alignmentChild,
            //   });
            // } else if (column == "tarifaConFactura") {
            //   dataRow.push({
            //     text: formatMoney(Object.byString(row, column), ",", "."),
            //alignment: "center",
            // alignment: headers[i].alignmentChild,
            //   });
            // } else {
            // if (column == "isAppointmentHome") {
            //   if (Object.byString(row, column) == "true") {
            //     dataRow.push({
            //       text: "Domicilio",
            //     });
            //   } else {
            //     dataRow.push({
            //       text: "Consultorio",
            //     });
            //   }
            // } else {
            dataRow.push({
              text: Object.byString(row, column),
              //alignment: "center",
              // alignment: headers[i].alignmentChild,
            });
            //  }
            // i++;
          });
  
          body.push(dataRow);
        });
  
        return body;
      }

      getHourReport(selectedDate) {
        var splitfecha = selectedDate.split("-");
        let cDay = splitfecha[2];
        let cMonth = splitfecha[1];
        let cYear = splitfecha[0];
  
        return cDay + "/" + cMonth + "/" + cYear + " ";
      }

      printerMedicalNote(titleDoc, titleReport,orientationDescription,pageSizeDescription,patientSelected, noteSelected){
         //https://github.com/bpampuch/pdfmake/tree/0.2/examples       
         
         var nota = "";
         if(noteSelected.isSOAP)
        {
          nota = [
            {
              style: "subtitle2",
               text:"Subjetivo",  
               margin: [0, 2],          
             }, 
             {
              text: (noteSelected.subjective) ? noteSelected.subjective: "Sin datos",    
              margin: [0, 2],            
            },       
            {
              margin: [0, 3],
              text:""
             },
            {
              style: "subtitle2",
               text:"Objetivo",  
               margin: [0, 2],          
             },
             {
              text: (noteSelected.objective) ? noteSelected.objective:"Sin datos",    
              margin: [0, 2],            
            },
            {
              margin: [0, 3],
              text:""
             },
            {
              style: "subtitle2",
               text:"Análisis",  
               margin: [0, 2],          
             },
             {
              text: (noteSelected.analysis) ? noteSelected.analysis:"Sin datos",    
              margin: [0, 2],            
            },
            {
              margin: [0, 3],
              text:""
             },
            {
              style: "subtitle2",
               text:"Plan",  
               margin: [0, 2],          
             },
             {
              text: (noteSelected.plan) ? noteSelected.plan:"Sin datos",    
              margin: [0, 2],            
            }
          ]
        }
        else
        {
          nota = [
            
             {
              text: (noteSelected.note) ? noteSelected.note:"Sin datos",    
              margin: [0, 2],            
            },     
          ]
        }

      var docDefinition = {
        info: {
           title: titleDoc,            
         },
         footer: function(currentPage, pageCount) { return [{ text: currentPage.toString() + ' de ' + pageCount,  alignment: "right",  margin: [0, 15, 10, 20]}] },         
        header: {
           columns: [
             {
               text: store.getters.user.data.displayName,
               alignment: "left",
               margin: [20, 15, 10, 0],
             },
             {
               //text: "Fecha: " + this.getHourReport(new Date().toISOString().substr(0, 10)),
               text: "Fecha: " + this.getHourReport(noteSelected.dateNote),
               alignment: "right",
               margin: [0, 15, 10, 20],
             },
           ],
         },
         pageOrientation: orientationDescription,
         pageSize: pageSizeDescription,
         compress: true,
        content: [
         {
           text: titleReport,
           style: "header",
           alignment: "center",
           margin: [0, 20],
         },         
         {
           style: "subtitle",                    
           text: "Paciente",
         },
         {
           text: patientSelected.fullName,   
           margin: [0, 2],       
         },
         {
          margin: [0, 10],
          text:""
         },
         {
          style: "subtitle",
           text:"Alergias",          
         },
         {
          text: (noteSelected.allergies) ? noteSelected.allergies:"Sin alergias",    
          margin: [0, 2],            
        },
        {
          margin: [0, 10],
          text:""
        },
        {
          style: "subtitle",
           text:"Signos vitales y somatometría",          
         },
        {
          style: 'tableExample',
          margin: [0, 2],       
          table: {
            headerRows: 1,
           // widths: ['*', '*', '*', '*','*','*','*','*'],            
            body: [
              [{ text: 'Temperatura', style: 'tableHeader' }, { text: 'Frecuencia respiratoria', style: 'tableHeader' }, { text: 'Frecuencia cardiaca', style: 'tableHeader' },
               { text: 'Presión arterial', style: 'tableHeader' },{ text: 'Satutarión oxígeno', style: 'tableHeader' }, { text: 'Peso', style: 'tableHeader' },
              { text: 'Talla', style: 'tableHeader' },{ text: 'IMC', style: 'tableHeader' }],
              [
                {text: (noteSelected.temperature) ? noteSelected.temperature + " °C" : "----", style: 'tableRow'}, 
                {text: (noteSelected.breathingFrequency) ? noteSelected.breathingFrequency + "/min":"----", style: 'tableRow'}, 
                {text: (noteSelected.heartRate) ? noteSelected.heartRate + "/min":"----", style: 'tableRow'}, 
                {text: (noteSelected.bloodPressure) ? noteSelected.bloodPressure + " mmHg":"----", style: 'tableRow'}, 
                {text: (noteSelected.oxygenSaturation) ? noteSelected.oxygenSaturation + "%":"----", style: 'tableRow'},
                {text: (noteSelected.weight) ? noteSelected.weight + " Kg":"----", style: 'tableRow'},
                {text: (noteSelected.size) ? noteSelected.size + " m":"----", style: 'tableRow'}, 
                {text: (noteSelected.imc) ? noteSelected.imc + " Kg/m²":"----", style: 'tableRow'}]
            ]
          },
        },
        {
          margin: [0, 10],
          text:""
         },
         {
          style: "subtitle",
           text:"Nota médica",          
         },
         {
          margin: [0, 5],
          text:""
         },        
         nota,
        {
          margin: [0, 10],
          text:""
         },
         {
          style: "subtitle",
           text:"Diagnósticos",          
         },
         {
          margin: [0, 5],
          text:""
         },         
         {
          text: (noteSelected.diagnostics) ? noteSelected.diagnostics:"Sin datos",    
          margin: [0, 2],            
        },  
        {
          margin: [0, 10],
          text:""
         },
         {
          style: "subtitle",
           text:"Tratamiento",          
         },
         {
          margin: [0, 5],
          text:""
         },         
         {
          text: (noteSelected.treatment) ? noteSelected.treatment: "Sin datos",    
          margin: [0, 2],            
        },  
        {
          margin: [0, 10],
          text:""
         },
         {
          style: "subtitle",
           text:"Estudios de laboratorio y gabinete",          
         },
         {
          margin: [0, 5],
          text:""
         },         
         {
          text: (noteSelected.laboratoryStudies) ? noteSelected.laboratoryStudies:"Sin datos",    
          margin: [0, 2],            
        },  
       ],
       styles: {
         header: {
           fontSize: 18,
           bold: true,
         },
         title: {
           fontSize: 16,
           bold: true,
         },
         subtitle: {
           fontSize: 14,
           bold: true,
         },
         subtitle2: {
          fontSize: 12,
          bold: true,
        },
         tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black',
          fillColor: '#eeeeee',
          alignment: 'justify',
        },
        tableRow: {
          bold: false,
          fontSize: 10,
          color: 'black',
          //fillColor: '#eeeeee',
          alignment: 'justify',
        }
       },
       defaultStyle: {
         columnGap: 20,
       },
     };
        pdfMake.createPdf(docDefinition).open();
    }
    
  }
  
  export default PrintService