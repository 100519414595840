class MobileService {
  //https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    isMobile(){
        return (
            (navigator.userAgent.match(/Mobile/i)) ||
            (navigator.userAgent.match(/Android/i)) ||
            (navigator.userAgent.match(/webOS/i)) ||
            (navigator.userAgent.match(/iPhone/i)) ||
            (navigator.userAgent.match(/iPod/i)) ||
            (navigator.userAgent.match(/iPad/i)) ||
            (navigator.userAgent.match(/IEMobile/i)) ||
            (navigator.userAgent.match(/Windows Phone/i)) ||
            (navigator.userAgent.match(/BlackBerry/i))
        );
    }

    getOrientation() {
        var current_mode=null
        if (window.matchMedia("(orientation: portrait)").matches) {
            current_mode = 'Portrait';
        }
        else if (window.matchMedia("(orientation: landscape)").matches) {
            current_mode = 'Landscape';
        }
        else {
            current_mode = window.innerWidth < window.innerHeight ? "Portrait" : "Landscape";
        }
        return current_mode
    }
    
  
}

export default MobileService