import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify();


/**
 * import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify);


export default new Vuetify({
  theme: {
    defaultTheme: "dark",
   
    themes: {
      light: {
        primary: colors.purple,
        secondary: colors.grey.darken1,
        accent: colors.shades.black, 
        error: colors.red.accent3,
      },
      dark: {
        primary: colors.blueGrey.darken2,
        secondary: colors.blueGrey.lighten2,
        accent: colors.blueGrey.darken3,
      },
    },
  },
});
 */


/*import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const customDarkTheme = {
  dark: true,
  colors: {
    background: "#15202b",
    surface: "#15202b",
    primary: "#3f51b5",
    secondary: "#03dac6",
    error: "#ff5722",
  },
};

const customLightTheme = {
  dark: false,
  colors: {
    background: "#eee",
    surface: "#15202b",
    primary: "#3f51b5",
    secondary: "#00ccff",
    error: "#ffcc00",
  },
};

export default new Vuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      customDarkTheme,
      customLightTheme,
    },
  },
});


*/