class DateService {
    formatDate(date) {
        if (!date) return null;
          
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }

      parseDate(date) {
        if (!date) return null;
  
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }  
  
      getCurrentDateWithOutTime()
      {
        const current = new Date();
        var mes = current.getMonth() + 1;
        mes = mes < 10 ? "0" + mes : mes;
        var dia = current.getDate();
        dia = dia < 10 ? "0" + dia : dia;
        return `${current.getFullYear()}-${mes}-${dia}`;
      }

      getCurrentDateWithTime()
      {
        const current = new Date();
        var mes = current.getMonth() + 1;
        mes = mes < 10 ? "0" + mes : mes;
        var dia = current.getDate();
        dia = dia < 10 ? "0" + dia : dia;
        var currentDate = `${current.getFullYear()}-${mes}-${dia}`
        const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        const dateTime = currentDate +' '+ time;
        return dateTime;
      }

      getDateWithTime(date,hour, minutes, seconds)
      {
        if (hour <= 9) hour = "0" + hour;
        if (minutes <= 9) minutes = "0" + minutes;
        if (seconds <= 9) seconds = "0" + seconds;
                 
        const current = new Date(date);
        current.setDate(current.getDate()+1);       
        current.setHours(hour);
        current.setMinutes(minutes);
        current.setSeconds(seconds);
        var mes = current.getMonth() + 1;
        mes = mes < 10 ? "0" + mes : mes;
        var dia = current.getDate();
        dia = dia < 10 ? "0" + dia : dia;
        var currentDate = `${current.getFullYear()}-${mes}-${dia}`
        const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        const dateTime = currentDate +' '+ time;
      
        return dateTime;
      }
      
}

export default DateService