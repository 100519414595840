import store from '../store/index'

class DashboardService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}dashboard`
    }  

    GetDashboards() {        
        let self = this;           
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetDashboards`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }
}

export default DashboardService