import store from '../store/index'

class MercadoPagoService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}mercadopago`
    }      

    CreatePreference(prefer) {      
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        //self.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        return self.axios.post(`${self.baseUrl}/CreatePreference`, prefer);
    }

}

export default MercadoPagoService