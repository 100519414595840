<template>
  <div id="app" v-bind:class="currentTheme">
    <div class="context-card2">
      <!--Con la etiqueta v-sheet simulo la parte pintada de alert en card-->
      <v-sheet
        outlined
        :color="selectedEvent.color"
        rounded="0"
        max-height="13"
      >
        <br />
      </v-sheet>
      <v-toolbar flat class="context-toolbar" color="transparent">
        <v-toolbar-title>
          <div class="context-title2" v-if="!isMobile">
            <h5>{{ selectedEvent.name }}</h5>
          </div>
          <div class="context-title2" v-else>
            <h6>{{ selectedEvent.name }}</h6>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon right offset-y @click="closeWindow('cerrar')">
          <v-icon class="context-icon-color">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="selectedEvent">
        <v-container>
          <v-layout row justify-space-around>
            <div v-if="!isMobile">
              <v-row align="center" justify="center" class="mt-5">
                <v-chip
                  class="d-flex pa-2 rounded-lg context-button-style"
                  label
                  outlined
                >
                  <span>
                    <v-icon>today</v-icon>
                    Fecha: {{ selectedEvent.appointmentDate }}
                  </span>
                </v-chip>
                <v-chip
                  class="d-flex pa-2 rounded-lg ml-4 context-button-style"
                  label
                  outlined
                >
                  <span>
                    <v-icon>schedule</v-icon>
                    Hora: {{ selectedEvent.horario }}
                  </span>
                </v-chip>
              </v-row>
            </div>
            <div v-else>
              <v-row align="center" justify="center" class="mt-3">
                <v-chip
                  class="pa-1 rounded-lg context-button-style"
                  label
                  outlined
                  small
                >
                  <span>
                    <v-icon>today</v-icon>
                    Fecha: {{ selectedEvent.formattedDate }}
                  </span>
                </v-chip>
                <v-chip
                  class="pa-1 rounded-lg context-button-style ml-1"
                  label
                  outlined
                  small
                >
                  <span>
                    <v-icon>schedule</v-icon>
                    Hora: {{ selectedEvent.horario }}
                  </span>
                </v-chip>
              </v-row>
            </div>
            <!--  <v-flex xs12 md12>
                <div flat>
                  <v-container>
                    <v-row justify="center">
                     
                     
                    </v-row>
                   <v-row justify="center" v-else>
                      
                    </v-row>
                  </v-container>
                </div>
              </v-flex>-->
            <v-flex xs12 md8 v-show="!selectedEvent.isDisabledDate">
              <div class="mt-5" flat>
                <v-row justify="center">
                  <v-alert
                    :color="selectedEvent.color"
                    elevation="4"
                    prominent
                    dense
                    dark
                    class="mx-auto rounded-xl"
                  >
                    <span v-if="selectedEvent.scheduleState === 'Programada'">
                      Cita programada
                    </span>
                    <span v-if="selectedEvent.scheduleState === 'Confirmada'">
                      Cita confirmada</span
                    >
                    <span v-if="selectedEvent.scheduleState === 'Cancelada'">
                      Cita cancelada</span
                    >
                  </v-alert>
                </v-row>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card
          outlined
          class="context-button-style pa-5"
          v-show="!selectedEvent.isDisabledDate"
        >
          <v-flex xs12 md12>
            <div class="flat">
              <v-chip
                v-if="selectedEvent.typeAppointment"
                color="orange"
                dark
                text-color="white"
                label
                small
              >
                <span>
                  <v-icon>verified</v-icon> Paciente tipo "{{
                    selectedEvent.typeAppointment
                  }}"
                </span>

                <!-- <v-icon right v-if="selectedEvent.categoria === 'Premium'">
                      star
                    </v-icon>-->
              </v-chip>
              <v-chip
                v-else
                color="#F1C40F"
                text-color="white"
                label
                small
                dark
              >
                <span>
                  <v-icon>warning</v-icon> No se ha definido el tipo de paciente
                </span>

                <!-- <v-icon right v-if="selectedEvent.categoria === 'Premium'">
                      star
                    </v-icon>-->
              </v-chip>
            </div>
          </v-flex>

          <v-layout row wrap justify-space-around class="mt-3">
            <v-flex xs12 md12 v-if="selectedEvent.typeAppointment === 'Pemex'">
              <span>
                <p>
                  <v-icon class="context-icon-color">tag</v-icon>
                  <span class="context-icon-color">
                    No Ficha: {{ selectedEvent.patient.numberSheet }}
                  </span>
                </p>
              </span>
            </v-flex>
            <v-flex xs12 md12>
              <span>
                <p>
                  <v-icon class="context-icon-color">face</v-icon>
                  <span class="context-icon-color">
                    Paciente {{ selectedEvent.patientState }}
                  </span>
                </p>
              </span>
            </v-flex>

            <v-flex xs12 md12>
              <div v-if="selectedEvent.appointmentPlace === 'Domicilio'">
                <p>
                  <v-icon class="context-icon-color">house</v-icon>
                  <span class="context-icon-color">
                    Consulta en {{ selectedEvent.appointmentPlace }}
                  </span>
                </p>
              </div>
              <div v-else-if="selectedEvent.appointmentPlace === 'Consultorio'">
                <p>
                  <v-icon class="context-icon-color">local_hospital</v-icon>
                  <span class="context-icon-color">
                    Consulta en {{ selectedEvent.appointmentPlace }}
                  </span>
                </p>
              </div>
              <div
                v-else-if="selectedEvent.appointmentPlace === 'Video Consulta'"
              >
                <span
                  ><p>
                    <v-icon class="context-icon-color">video_call</v-icon>
                    <span class="context-icon-color">
                      {{ selectedEvent.appointmentPlace }}
                    </span>
                  </p></span
                >
              </div>
            </v-flex>
            <v-flex xs12 md12>
              <div v-if="selectedEvent.phone">
                <span
                  ><p>
                    <v-icon class="context-icon-color">phone</v-icon>
                    <span class="context-icon-color">
                      {{ selectedEvent.phone }}
                    </span>
                  </p></span
                >
              </div>
            </v-flex>
            <v-flex xs12 md6>
              <v-btn
                rounded
                color="secondary"
                small
                right
                @click="showHistorySchedule()"
                ><v-icon left> history </v-icon>Historial de consultas</v-btn
              >
            </v-flex>
          </v-layout>

          <!--<v-flex xs11 md12>
              <div v-if="selectedEvent.email" class="d-flex pa-2" flat>
                <span><v-icon>email</v-icon> {{ selectedEvent.email }}</span>
              </div>
            </v-flex>
            <v-flex xs11 md12>
              <div v-if="selectedEvent.address" class="d-flex pa-2" flat>
                <span
                  ><v-icon>location_on</v-icon>
                  {{ selectedEvent.address }}</span
                >
              </div>
            </v-flex>-->
        </v-card>
        <div v-if="!selectedEvent.isDisabledDate">
          <v-card-actions v-show="selectedEvent.scheduleState == 'Programada'">
            <div v-if="!isMobile">
              <v-layout row align-center align-content-center>
                <v-spacer></v-spacer>
                <v-btn
                  class="white--text text-caption ml-2"
                  @click="confirmEvent()"
                  color="green darken-1"
                  rounded
                  small
                  dark
                >
                  <v-icon left> check </v-icon>
                  Confirmar
                </v-btn>
                <v-btn
                  class="white--text text-caption ml-2"
                  dark
                  @click="editEvent()"
                  color="blue darken-1"
                  rounded
                  small
                >
                  <v-icon left> edit </v-icon>
                  Editar
                </v-btn>
                <v-btn
                  class="white--text text-caption ml-2"
                  dark
                  @click="cancelEvent()"
                  color="red darken-4"
                  rounded
                  small
                >
                  <v-icon left> event_busy </v-icon>
                  Cancelar
                </v-btn>
                <v-btn
                  class="white--text text-caption ml-2"
                  dark
                  @click="deleteEvent()"
                  color="red darken-1"
                  rounded
                  small
                >
                  <v-icon left> delete </v-icon>
                  Eliminar
                </v-btn>
              </v-layout>
            </div>
            <div v-else class="px-3">
              <v-layout row align-center align-content-center>
                <v-col >
                  <v-btn
                    class="white--text text-caption"
                    @click="confirmEvent()"
                    color="green darken-1"
                    rounded
                    small
                    dark
                  >
                    <v-icon left> check </v-icon>
                    Confirmar
                  </v-btn>
                </v-col>
                <v-col >
                  <v-btn
                    class="white--text text-caption"
                    dark
                    @click="editEvent()"
                    color="blue darken-1"
                    rounded
                    small
                  >
                    <v-icon left> edit </v-icon>
                    Editar
                  </v-btn>
                </v-col>
              </v-layout>
              <v-layout row align-center align-content-center>
                <v-col >
                  <v-btn
                    class="white--text text-caption"
                    dark
                    @click="cancelEvent()"
                    color="red darken-4"
                    rounded
                    small
                  >
                    <v-icon left> event_busy </v-icon>
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    class="white--text text-caption"
                    dark
                    @click="deleteEvent()"
                    color="red darken-1"
                    rounded
                    small
                  >
                    <v-icon left> delete </v-icon>
                    Eliminar
                  </v-btn>
                </v-col>
              </v-layout>
            </div>
            <!-- <template v-else>
              <v-btn
                class="ma-2 white--text text-caption"
                @click="confirmEvent()"
                color="green darken-1"
                rounded
                small
              >
                <v-icon left> check </v-icon>
               
             //esto es para alinear dos componentes de manera vertical
             <div class="wrapper">
              <div>
                <v-icon left> check </v-icon>
              </div>
              <div class="text-6">Confirmar</div>
            </div>
              </v-btn>

              <v-btn
                class="ma-2 white--text text-caption"
                @click="editEvent()"
                color="blue darken-1"
                rounded
                small
              >
                <v-icon left> edit </v-icon>
              </v-btn>

              <v-btn
                class="ma-2 white--text text-caption"
                @click="cancelEvent()"
                color="red darken-4"
                rounded
                small
              >
                <v-icon left> event_busy </v-icon>
              </v-btn>

              <v-btn
                class="ma-2 white--text text-caption"
                @click="deleteEvent()"
                color="red darken-1"
                rounded
                small
              >
                <v-icon left> delete </v-icon>
              </v-btn>
            </template>-->
          </v-card-actions>
          <v-card-actions v-show="selectedEvent.scheduleState == 'Confirmada'">
            <v-layout row align-center align-content-center>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text text-caption ml-2"
                dark
                @click="editEvent()"
                color="blue darken-1"
                rounded
                small
              >
                <v-icon left> edit </v-icon>
                Editar
              </v-btn>
              <v-btn
                class="white--text text-caption ml-2"
                dark
                @click="cancelEvent()"
                color="red darken-4"
                rounded
                small
              >
                <v-icon left> event_busy </v-icon>
                Cancelar
              </v-btn>
            </v-layout>
            <!--  <v-spacer></v-spacer>
            <template v-if="!isMobile">
              <v-btn
                class="ma-2 white--text text-caption"
                @click="editEvent()"
                color="blue darken-1"
                rounded
                small
              >
                <v-icon left> edit </v-icon>
                Editar
              </v-btn>

              <v-btn
                class="ma-2 white--text text-caption"
                @click="cancelEvent()"
                color="red darken-4"
                rounded
                small
              >
                <v-icon left> event_busy </v-icon>
                Cancelar
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="ma-2 white--text text-caption"
                @click="editEvent()"
                color="blue darken-1"
                rounded
                small
              >
                <v-icon left> edit </v-icon>
              </v-btn>

              <v-btn
                class="ma-2 white--text text-caption"
                @click="cancelEvent()"
                color="red darken-4"
                rounded
                small
              >
                <v-icon left> event_busy </v-icon>
              </v-btn>
            </template>-->
          </v-card-actions>
          <v-card-actions v-show="selectedEvent.scheduleState == 'Cancelada'">
            <v-layout row align-content-space-between>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text text-caption ml-2"
                @click="scheduleEvent(selectedEvent)"
                color="grey darken-1"
                rounded
                small
                dark
              >
                <v-icon left> schedule </v-icon>
                Programar de nuevo
              </v-btn>
            </v-layout>

            <!-- <v-spacer></v-spacer>
            <template v-if="!isMobile">
              <v-btn
                class="ma-2 white--text text-caption"
                @click="scheduleEvent(selectedEvent)"
                color="grey darken-1"
                rounded
                small
              >
                <v-icon left> schedule </v-icon>
                Programar de nuevo
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="ma-2 white--text text-caption"
                @click="scheduleEvent(selectedEvent)"
                color="grey darken-1"
                rounded
                small
              >
                <v-icon left> schedule </v-icon>
              </v-btn>
            </template>-->
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-actions v-show="role == 'Administrator' || role == 'Manager'">
            <v-layout row align-content-space-between class="mt-2">
              <v-spacer></v-spacer>
              <v-btn
                class="white--text text-caption"
                dark
                @click="deleteEventDisabled()"
                color="red darken-1"
                rounded
                small
                width="110"
              >
                <v-icon left> delete </v-icon>
                Eliminar
              </v-btn>
            </v-layout>

            <!--<v-spacer></v-spacer>
            <template v-if="!isMobile">
              <v-btn
                class="white--text text-caption"
                dark
                @click="deleteEventDisabled()"
                color="red darken-1"
                rounded
                small
                width="110"
              >
                <v-icon left> delete </v-icon>
                Eliminar
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="ma-2 white--text text-caption"
                @click="deleteEventDisabled()"
                color="red darken-1"
                rounded
                small
              >
                <v-icon left> delete </v-icon>
              </v-btn>
            </template>-->
          </v-card-actions>
        </div>
      </v-form>
      <v-dialog
        v-model="showHistory"
        :close-on-content-click="false"
        offset-x
        max-width="600px"
        persistent
        transition="dialog-bottom-transition"
      >
        <ShowPatientHistorySchedule
          :selectedEvent="selectedEvent"
          @closeScheduleDetail="closeWindow"
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import ShowPatientHistorySchedule from "./ShowPatientHistorySchedule.vue";

export default {
  components: { ShowPatientHistorySchedule },
  data() {
    return {
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
      showHistory: false,
      role: localStorage.getItem("role"),
    };
  },
  props: {
    selectedEvent: null,
  },
  computed: {},
  mounted() {},

  methods: {
    async confirmEvent() {
      //confirma una consulta
      let item = {
        id: this.selectedEvent.id,
        patientId: this.selectedEvent.patientId,
        appointmentDate: this.selectedEvent.appointmentDate,
        start: this.selectedEvent.horaInicio,
        end: this.selectedEvent.horaFin,
        scheduleState: "Confirmada", //Programada,Confirmada,Cancelada
        statusColor: "green darken-1",
        appointmentPlace: this.selectedEvent.appointmentPlace,
      };
      //selectedEvent.scheduleState = "Confirmada";
      //selectedEvent.color = "green darken-1";
      //this.selectedEvent = selectedEvent;

      await apiServices.scheduleService
        .UpdateSchedule(item)
        .then(() => {
          this.closeWindow("confirmEvent");
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    editEvent() {
      this.closeWindow("editEvent");
    },
    async cancelEvent() {
      //cancela una consulta
      let item = {
        id: this.selectedEvent.id,
        patientId: this.selectedEvent.patientId,
        appointmentDate: this.selectedEvent.appointmentDate,
        start: this.selectedEvent.horaInicio,
        end: this.selectedEvent.horaFin,
        scheduleState: "Cancelada", //Programada,Confirmada,Cancelada
        statusColor: "red darken-4",
        appointmentPlace: this.selectedEvent.appointmentPlace,
      };
      //selectedEvent.scheduleState = "Cancelada";
      //selectedEvent.color = "red darken-4";
      //this.selectedEvent = selectedEvent;

      await apiServices.scheduleService
        .UpdateSchedule(item)
        .then(() => {
          this.closeWindow("cancelEvent");
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    deleteEvent() {
      //elimina una consulta
      this.$fire({
        title: "Desea eliminar la consulta?",
        text: this.selectedEvent.horario + " " + this.selectedEvent.name,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          apiServices.scheduleService
            .DeleteSchedule(this.selectedEvent.id)
            .then(() => {
              this.closeWindow("deleteEvent");
            })
            .catch((error) => {
              if (error.response) {
                // get response with a status code not in range 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // no response
                console.log(error.request);
                // instance of XMLHttpRequest in the browser
                // instance ofhttp.ClientRequest in node.js
              } else {
                // Something wrong in setting up the request
                console.log("Error", error.message);
              }
              console.log("Error.config: " + error.config);
            });
        }
      });
    },
    deleteEventDisabled() {
      //elimina un horario deshabilitado, solo roles permitidos
      this.$fire({
        title: "Desea eliminar el horario deshabilitado?",
        text:
          this.selectedEvent.appointmentDate +
          " " +
          this.selectedEvent.horario +
          " " +
          this.selectedEvent.name,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          apiServices.ScheduleDisabledService.DeleteScheduleDisabled(
            this.selectedEvent.id
          )
            .then(() => {
              this.closeWindow("deleteEventDisabled");
            })
            .catch((error) => {
              if (error.response) {
                // get response with a status code not in range 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // no response
                console.log(error.request);
                // instance of XMLHttpRequest in the browser
                // instance ofhttp.ClientRequest in node.js
              } else {
                // Something wrong in setting up the request
                console.log("Error", error.message);
              }
              console.log("Error.config: " + error.config);
            });
        }
      });
    },
    async scheduleEvent() {
      //programa de nuevo una consulta cancelada

      let item = {
        id: this.selectedEvent.id,
        patientId: this.selectedEvent.patientId,
        appointmentDate: this.selectedEvent.appointmentDate,
        start: this.selectedEvent.horaInicio,
        end: this.selectedEvent.horaFin,
        scheduleState: "Programada", //Programada,Confirmada,Cancelada
        statusColor: "grey darken-1",
        appointmentPlace: this.selectedEvent.appointmentPlace,
      };
      //selectedEvent.scheduleState = "Programada";
      //selectedEvent.color = "grey darken-1";
      //this.selectedEvent = selectedEvent;

      await apiServices.scheduleService
        .UpdateSchedule(item)
        .then(() => {
          this.closeWindow("scheduleEvent");
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    showHistorySchedule() {
      this.showHistory = true;
    },
    closeWindow(value) {
      if (value == "confirmEvent") {
        let result = {
          isVisible: false,
          tipo: "confirmEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "editEvent") {
        let result = {
          isVisible: false,
          tipo: "editEvent",
          item: this.selectedEvent,
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "cancelEvent") {
        let result = {
          isVisible: false,
          tipo: "cancelEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "deleteEvent") {
        let result = {
          isVisible: false,
          tipo: "deleteEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "deleteEventDisabled") {
        let result = {
          isVisible: false,
          tipo: "deleteEventDisabled",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "scheduleEvent") {
        let result = {
          isVisible: false,
          tipo: "scheduleEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEventDetail", result);
      } else if (value === "closeScheduleDetail") {
        this.showHistory = false;
      }

      //  this.resetForm();
    },
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
