import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// Vuex: for services, shared components, etc
import store from './store/index'
import VueMask from 'v-mask'
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueMask);
//Vue.directive('mask', VueMaskDirective);
//Vue.config.productionTip = false
Vue.use(VueSimpleAlert);

new Vue({
  router,
  store,
  vuetify,
  data() {
    return {
      myStyle: {
        backgroundColor: "grey"
      }
    };
  },
  render: h => h(App)
  
}).$mount('#app');
