<template>
  <div id="app" v-bind:class="currentTheme">
    <v-toolbar flat class="context-toolbar">
      <v-toolbar-title class="context-title2">
        <h5 v-if="!isMobile">Modificar información</h5>
        <h6 v-else>Modificar información</h6>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon bottom right offset-y @click="closeWindow('cerrar')">
        <v-icon class="context-icon-color">close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="context-card2">
      <v-form ref="infoSelected" @submit.prevent="submit" class="mx-4">
        <v-container>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-text-field
                v-model="infoSelected.displayName"
                :rules="rules.displayName"
                label="Nombre Completo"
                required
                outlined
                dense
                placeholder="Introduce el nombre completo"
                class="custom-placeholer-color"
                append-icon="person"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-text-field
                v-model="infoSelected.phone"
                :rules="rules.phone"
                class="custom-placeholer-color"
                label="Teléfono"
                required
                outlined
                dense
                append-icon="phone"
                @input="enforcePhoneFormat()"
                maxlength="16"
                placeholder="(123) 456-7890"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
            justify-space-around
            v-if="role == 'Administrator' || role == 'Manager'"
          >
            <v-flex xs12 md12>
              <v-text-field
                v-model="infoSelected.specialty"
                :rules="rules.specialty"
                label="Especialidad"
                required
                outlined
                dense
                placeholder="Introduce la especialidad"
                class="custom-placeholer-color"
                append-icon="contact_page"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around v-else>
            <v-flex xs12 md12>
              <v-text-field
                v-model="infoSelected.specialty"
                :rules="rules.specialty"
                label="Cargo"
                required
                outlined
                dense
                placeholder="Introduce el cargo"
                class="custom-placeholer-color"
                append-icon="contact_page"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-checkbox
                v-model="infoSelected.updatePassword"
                label="Cambiar password"
                :dark="isDark"
                @click="LimpiarPassword()"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
            justify-space-around
            v-show="infoSelected.updatePassword"
          >
            <v-flex xs12 md12>
              <v-text-field
                :append-icon="show3 ? 'visibility' : 'visibility_off'"
                v-model="password"
                :rules="rules.password"
                label="Nuevo password"
                outlined
                dense
                required
                :type="show3 ? 'text' : 'password'"
                placeholder="Introduce el password"
                class="mt-5 custom-placeholer-color"
                @click:append="show3 = !show3"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <!-- <v-divider class="mt-12"></v-divider> -->
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="!formIsValid"
            :loading="isLoading"
            class="context-button-style"
            type="submit"
            small
          >
            <v-icon left> save </v-icon>
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";

export default {
  data() {
    const defaultForm = Object.freeze({
      displayName: "",
      password: "",
      email: "",
      phone: "",
      specialty: "",
      updatePassword: false,
    });

    return {
      form: Object.assign({}, defaultForm),
      isLoading: false,
      rules: {
        displayName: [(val) => (val || "").length > 0 || "Nombre requerido."],
        phone: [(val) => (val || "").length > 0 || "Teléfono requerido."],
        specialty: [
          (val) => (val || "").length > 0 || "Especialidad requerida.",
        ],
        password: [(val) => (val || "").length > 0 || "Password es requerido."],
      },
      defaultForm,
      currentTheme: localStorage.getItem("theme-color"),
      show3: false,
      password: "",
      isMobile: false,
      isDark: false,
    };
  },
  props: {
    infoSelected: null,
    role: String,
  },
  computed: {
    formIsValid() {
      if (this.infoSelected.updatePassword) {
        return (
          this.infoSelected.displayName &&
          this.password &&
          this.infoSelected.phone &&
          this.infoSelected.specialty
        );
      } else {
        return (
          this.infoSelected.displayName &&
          this.infoSelected.phone &&
          this.infoSelected.specialty
        );
      }
    },
  },
  mounted() {
    //  this.isVisible = this.props.patientSelected.isVisible;
    // console.log(this.props.patientSelected);
    // this.patientSelected = this.props.patientSelected;
  },

  methods: {
    resetForm() {
      this.infoSelected = Object.assign({}, this.defaultForm);
      this.$refs.infoSelected.reset();
    },

    LimpiarPassword() {
      this.password = "";
    },

    enforcePhoneFormat() {
      let x = this.infoSelected.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.infoSelected.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    submit() {
      this.isLoading = true;
      let item = {
        //id: this.infoSelected.id,
        userName: this.infoSelected.displayName,
        password: this.password,
        email: this.infoSelected.email,
        phone: this.infoSelected.phone,
        specialty: this.infoSelected.specialty,
        updatePassword: this.infoSelected.updatePassword,
      };

      apiServices.userService
        .UpdateUser(item)
        .then(() => {
          // var userSelected = this.$store.getters.user.data;
          /*  let user = {
            displayName: item.userName,
            email: item.email,
            uid: userSelected.uid,
            role: userSelected.role,
            phone: item.phone,
            specialty: item.specialty,
          };
          this.$store.dispatch("fetchUser", user);*/
          this.closeWindow("editar");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    closeWindow(value) {
      if (value == "nuevo") {
        let result = {
          isVisible: false,
          tipo: "nuevo",
        };
        this.$emit("closeEditInformation", result);
      } else if (value == "editar") {
        let result = {
          isVisible: false,
          tipo: "editar",
        };
        this.$emit("closeEditInformation", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEditInformation", result);
      }
      //  this.resetForm();
    },
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
    if (this.currentTheme == "theme-black") this.isDark = true;
    else this.isDark = false;
  },
};
</script>
