import store from '../store/index'

class SettingService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}settings`
    }
    
    GetSettingById(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetSettingById/?id=${id}`);
    }

    GetSettings() {        
        let self = this;   
        
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetSettings`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }
    GetSettingsPagination(filter) {        
      let self = this;   
      
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.post(`${self.baseUrl}/GetSettings`,filter);
        /*{
          headers: {
            'Authorization': "Bearer " + token
          }
        }*/
  }
    /*getAll(params) {        
        let self = this;           
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token.token   
        return self.axios.get(`${self.baseUrl}`,{
                params
          });
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }
    }*/

    InsertSetting(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/InsertSetting`, model);
    }

    UpdateSetting(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdateSetting`, model);
    }

    DeleteSetting(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/DeleteSetting/${id}`);
    }
}

export default SettingService