<template>
  <div>
    <nav v-if="user.loggedIn">
      <v-app-bar
        app
        color="#2F4F4F"
        dark
        prominent
        shrink-on-scroll
        fade-img-on-scroll
        scroll-threshold="500"
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>
        <v-app-bar-nav-icon
          v-if="isMobile"
          class="context-icon-color"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <!--<v-app-bar-nav-icon
          v-else
          class="context-icon-color"
          @click="mini = !mini"
        ></v-app-bar-nav-icon>-->
        <v-app-bar-title class="text-uppercase">
          <h4>
            <span class="font-weight-light">Agenda</span> <span>Pacientes</span>
          </h4>
        </v-app-bar-title>

        <v-spacer></v-spacer>
        <!-- <v-menu offset-y>
      <template v-slot:activator="{ attrs, on }">
        <span
          class="mx-5 mr-10"
          style="cursor: pointer"
          v-bind="attrs"
          v-on="on"
        >
          <v-badge content="5" color="red" offset-y="10" offset-x="10">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </span>
      </template>
      <v-list three-line width="250">
        <template v-for="(item, index) in notif">
          <v-subheader
            v-if="item.header"
            :key="item.header"
            v-text="item.header"
          ></v-subheader>

          <v-divider
            v-else-if="item.divider"
            :key="index"
            :inset="item.inset"
          ></v-divider>

          <v-list-item v-else :key="item.title">
            <v-list-item-avatar>
              <v-img :src="item.avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu> -->
        <!-- <span class="mx-5 mr-10" style="cursor: pointer" v-bind="attrs" v-on="on">
        <v-button lass="mx-5 mr-10" color="red" offset-y="10" offset-x="10">
          <v-icon>settings</v-icon>
        </v-button>
      </span>-->

        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <span style="cursor: pointer" v-bind="attrs" v-on="on">
              <v-btn rounded icon dark class="context-icon-color-menu-drawer">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </span>
          </template>
          <v-list width="300" class="py-0 context-card2">
            <v-list-item router to="/information">
              <v-list-item-icon>
                <v-icon class="context-icon-color">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title><h5>Perfil</h5> </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Cambiar Password
          </v-list-item-title>
        </v-list-item>-->
            <v-list-item @click="signOut()">
              <v-list-item-icon>
                <v-icon class="context-icon-color">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title><h5>Cerrar sesión</h5> </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!--<v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <span style="cursor: pointer" v-bind="attrs" v-on="on">
            <v-chip link>
              <v-badge dot bottom color="green" offset-y="10" offset-x="10">
                <v-avatar size="40">
                  <v-img src="../assets/noimage.jpg" />
                </v-avatar>
              </v-badge>
              <span class="ml-3">{{ user.data.displayName }}</span>
            </v-chip>
          </span>
        </template>
        <v-list width="300" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <img src="../assets/noimage.jpg" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.data.displayName }}</v-list-item-title>
              <v-list-item-subtitle>{{
                user.data.specialty
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item router to="/information">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Perfil </v-list-item-title>
          </v-list-item>
           <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Cambiar Password
          </v-list-item-title>
        </v-list-item>
          <v-list-item @click="signOut()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Cerrar sesión </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>-->
      </v-app-bar>
      <v-navigation-drawer color="#2F4F4F" v-model="drawer" app v-if="isMobile">
        <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="450px"
        color="transparent"
        v-if="screenOrientation == 'Landscape'"
      >
      <v-container style="height: 520px">
         <v-layout row align-content-center column class="mt-10">
          <v-list-item>
            <v-list-item-avatar size="90">
              <v-img src="../assets/agenda.jpg"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-layout>
        <v-layout row align-content-center column>
          <v-list-item>
            <v-list-item-title class="white--text">
              <h3>{{ user.data.displayName }}</h3></v-list-item-title
            >
          </v-list-item>
        </v-layout>
        <v-layout row align-content-center column>
          <v-flex xs12 md12>
            <v-list-item>
              <v-list-item-title class="white--text">
                {{ user.data.specialty }}</v-list-item-title
              >
            </v-list-item>
          </v-flex>
        </v-layout>

        <v-layout row align-content-center column>
          <v-list-item>
            <v-list-item-title class="white--text">
              <v-btn color="primary" rounded x-small router to="/information"
                ><v-icon left>mdi-account</v-icon>
                Ver perfil
              </v-btn></v-list-item-title
            >
          </v-list-item>
        </v-layout>

        <v-list flat class="mt-5" v-if="user.loggedIn">
          <v-list-item-group v-model="selectedItem">
            <!--<v-list-item
            router
            to="/home"
            v-if="user.loggedInAll"
            active-class="border"
            class="ml-2 my-3"
            :ripple="false"
          >
            <v-list-item-icon>
              <v-icon>home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
            <v-list-item
              router
              to="/dashboards"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">dashboard</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Indicadores</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              router
              to="/calendar"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">calendar_month</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Calendario</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              router
              to="/patients"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">personal_injury</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Pacientes</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!--<v-list-item
              router
              to="/shopping"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="context-icon-color-menu-drawer"
                  >shopping_cart</v-icon
                >
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="context-button-text-color"
                  >Compras</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>-->
            <v-list-item
              router
              to="/settings"
              active-class="context-button-active-border"
              class="ml-2 my-3 mb-10"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">settings</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Configuración</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!--<v-img height="140" class="pa-4" src="../assets/agenda.jpg">
        <div class="text-center">
          <v-avatar class="mb-4"> </v-avatar>
          <h2 class="white--text">Agenda pacientes</h2>
        </div>
      </v-img>-->
      </v-container>
        </v-sheet>
        <div v-else>
           <v-layout row align-content-center column class="mt-10">
          <v-list-item>
            <v-list-item-avatar size="90">
              <v-img src="../assets/agenda.jpg"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-layout>
        <v-layout row align-content-center column>
          <v-list-item>
            <v-list-item-title class="white--text">
              <h3>{{ user.data.displayName }}</h3></v-list-item-title
            >
          </v-list-item>
        </v-layout>
        <v-layout row align-content-center column>
          <v-flex xs12 md12>
            <v-list-item>
              <v-list-item-title class="white--text">
                {{ user.data.specialty }}</v-list-item-title
              >
            </v-list-item>
          </v-flex>
        </v-layout>

        <v-layout row align-content-center column>
          <v-list-item>
            <v-list-item-title class="white--text">
              <v-btn color="primary" rounded x-small router to="/information"
                ><v-icon left>mdi-account</v-icon>
                Ver perfil
              </v-btn></v-list-item-title
            >
          </v-list-item>
        </v-layout>

        <v-list flat class="mt-5" v-if="user.loggedIn">
          <v-list-item-group v-model="selectedItem">
            <!--<v-list-item
            router
            to="/home"
            v-if="user.loggedInAll"
            active-class="border"
            class="ml-2 my-3"
            :ripple="false"
          >
            <v-list-item-icon>
              <v-icon>home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
            <v-list-item
              router
              to="/dashboards"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">dashboard</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Indicadores</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              router
              to="/calendar"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">calendar_month</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Calendario</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              router
              to="/patients"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">personal_injury</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Pacientes</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!--<v-list-item
              router
              to="/shopping"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="context-icon-color-menu-drawer"
                  >shopping_cart</v-icon
                >
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="context-button-text-color"
                  >Compras</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>-->
            <v-list-item
              router
              to="/settings"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon class="white--text">settings</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Configuración</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </div>
        
       
      </v-navigation-drawer>
      <v-navigation-drawer
        color="#2F4F4F"
        v-model="drawer"
        app
        :mini-variant.sync="mini"
        mini-variant-width="80"
        expand-on-hover
        permanent
        width="270px"
        v-else
      >
        <v-layout column align-content-center class="mt-10">
          <v-layout row align-content-center column>
            <v-list-item>
              <v-list-item-avatar size="120" v-if="!mini">
                <v-img src="../assets/agenda.jpg"></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar size="60" v-else>
                <v-img src="../assets/agenda.jpg"></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-layout>
          <v-layout row align-content-center column>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="white--text text-h6" v-if="!mini">
                  {{ user.data.displayName }}</v-list-item-title
                >
                <v-list-item-title class="white--text text-h6" v-else>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-layout>
          <v-layout row align-content-center column>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="white--text" v-if="!mini">{{
                  user.data.specialty
                }}</v-list-item-subtitle>
                <v-list-item-subtitle
                  class="white--text mt-3"
                  v-else
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-layout>
        </v-layout>

        <v-layout row wrap align-content-center column>
          <v-list-item>
            <v-list-item-title class="white--text" v-show="!mini">
              <v-btn color="primary" x-small router to="/information"
                ><v-icon left>mdi-account</v-icon>
                Ver perfil
              </v-btn></v-list-item-title
            >
          </v-list-item>
        </v-layout>

        <v-list flat class="mt-5" v-if="user.loggedIn">
          <v-list-item-group v-model="selectedItem">
            <!-- <v-list-item
            router
            to="/home"
            v-if="user.loggedInAll"
            active-class="border"
            class="ml-2 my-3"
            :ripple="false"
          >
            <b></b>
            <b></b>
            <v-list-item-icon>
              <v-icon large>home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="ml-5">Inicio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
            <v-list-item
              router
              to="/dashboards"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon size="30px" class="white--text">dashboard</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="ml-5 white--text"
                  >Indicadores</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              router
              to="/calendar"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon size="30px" class="white--text">calendar_month</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="ml-5 white--text"
                  >Calendario</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              router
              to="/patients"
              v-if="user.loggedInAll"
              active-class="context-button-active-border"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon size="30px" class="white--text">personal_injury</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="ml-5 white--text"
                  >Pacientes</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
              router
              to="/shopping"
              active-class="context-button-active-border"
              class="ml-2 my-3"
              :ripple="false"
            >
              <b></b>
              <b></b>
              <v-list-item-icon>
                <v-icon large class="context-icon-color-menu-drawer"
                  >shopping_cart</v-icon
                >
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="ml-5 context-button-text-color"
                  >Compras</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>-->
            <v-list-item
              router
              to="/settings"
              active-class="context-button-active-border"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon size="30px" class="white--text">settings</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="ml-5 white--text"
                  >Configuración</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!--<v-img height="140" class="pa-4" src="../assets/agenda.jpg">
        <div class="text-center">
          <v-avatar class="mb-4"> </v-avatar>
          <h2 class="white--text">Agenda pacientes</h2>
        </div>
      </v-img>-->
      </v-navigation-drawer>
    </nav>

    <nav v-else>
      <v-app-bar app color="#2F4F4F" dark>
        <v-layout row wrap align-content-space-around>
          <v-flex xs11 md12>
            <v-app-bar-title class="text-uppercase">
              <h4>
                <span class="font-weight-light">Agenda</span>
                <span>Pacientes</span>
              </h4>
            </v-app-bar-title>

            <v-spacer></v-spacer>
          </v-flex>
        </v-layout>

        <v-spacer></v-spacer>

        <template>
          <v-btn
            dark
            small
            rounded
            color="blue darken-1"
            @click="$router.push('/login').catch(() => {})"
          >
            <v-icon left> login </v-icon>
            <span>Login</span>
          </v-btn>
          <!-- <v-btn
          text
          small
          rounded
          color="grey darken-1"
          @click="$router.push('/register').catch(() => {})"
        >
          <v-icon left> person_add </v-icon>
          <span>Registrarse</span>
        </v-btn>-->
        </template>
      </v-app-bar>
    </nav>
  </div>
</template>
<script>
import helperServices from "@/Services/helperServices";
import { mapGetters } from "vuex";

/*window.addEventListener(
  "orientationchange",
  function () {
    if (window.matchMedia("(orientation: portrait)").matches) {
      //alert("PORTRAIT");
      localStorage.setItem("orientation", "PORTRAIT");
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      //alert("LANSCAPE");
      localStorage.setItem("orientation", "LANSCAPE");
    }
  },
  false
);*/

export default {
  data() {
    return {
      drawer: null,
      mini: true,
      // user: {},
      items: [
        { title: "Inicio", icon: "home", to: "/home" },
        { title: "Indicadores", icon: "dashboard", to: "/dashboards" },
        { title: "Calendario", icon: "calendar_month", to: "/calendar" },
        { title: "Pacientes", icon: "face", to: "/patients" },
        // { title: "Consultorios", icon: "local_hospital", to: "/consultingrooms" },
        { title: "Mis datos", icon: "info", to: "/information" },
        // { title: "Ayuda", icon: "help", to: "/help" },
        //{ title: "Compras", icon: "shopping_cart", to: "/shopping" },
        { title: "Configuración", icon: "settings", to: "/settings" },
      ],
      notif: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Brunch this weekend?",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Oui oui",
          subtitle:
            '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Birthday gift",
          subtitle:
            '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "Recipe to try",
          subtitle:
            '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ],
      isMobile: false,
      screenOrientation: "",
      selectedItem: false,
      currentTheme: localStorage.getItem("theme-color"),
      isPortrait: localStorage.getItem("orientation"),
    };
  },
  methods: {
    signOut() {
      this.$store.dispatch("fetchUser", null);
      localStorage.removeItem("paypalConfig");
      //this.$store.dispatch("fetchUserSuscription", null);
      //this.$store.dispatch("fetchToken", null);
      localStorage.removeItem("theme-color");
      //window.location.reload();
      this.$router.push("/login").catch(() => {});
      //localStorage.removeItem('user-token') // clear your user's token from localstorage
      // remove the axios default header
      //delete axios.defaults.headers.common["Authorization"];
    },
    //activarSuscripcion() {
    //  this.$router.push("/suscription").catch(() => {});
    //},
  },
  created() {
    //this.user = this.$store.getters.user;
    this.isMobile = helperServices.MobileService.isMobile();
    this.screenOrientation = helperServices.MobileService.getOrientation();
  },
  computed: {
    ...mapGetters({
      nameFromStore: "user",
    }),
    user: {
      get() {
        return this.nameFromStore;
      },
      set(newName) {
        return newName;
      },
    },   
  },
  mounted() {
    this.isMobile = helperServices.MobileService.isMobile();
    this.screenOrientation = helperServices.MobileService.getOrientation();
  },
};
</script>

<style>
.v-navigation-drawer__content {
  overflow-y: hidden !important;
}
/*
.border {
  background: #fff;
  margin: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-decoration: none;
}

.border b:nth-child(1) {
  position: absolute;
  top: -20px;
  height: 20px;
  right: 0px;
  width: 83%;
  background: #fff;

  display: none;
}
.border b:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  background: black;
}


.border b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  background: black;
}
.border b:nth-child(1),
.border b:nth-child(2) {
  display: block;
}*/
</style>
