<!--https://v2.vuetifyjs.com/en/components/app-bars/#menu-->
<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
//integracion mercadopago
//https://www.youtube.com/watch?v=zx6YzaIwEl8
//https://www.youtube.com/watch?v=wYP0OVYTgYk
//stripe https://www.youtube.com/watch?v=d38QXjPy3Gs
import Navbar from "@/components/Navbar";
import Axios from "axios";

export default {
  name: "App",
  components: { Navbar },
  data: () => ({}),

  methods: {},
  created() {
    Axios.interceptors.response.use(
      (response) => {
        if (response.status === 401 || response.status === 404) {
          //add your code
          //alert("You are not authorized");
          this.$fire({
            position: "top-end",
            title: "No estas autorizado!!!",
            // text: "Edición exitosa!!!",
            type: "success",
            timer: 3000,
            toast: true,
            //grow:'row',
            //width: 300,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });

          this.$store.dispatch("fetchUser", null);
          this.$router.push("/login").catch(() => {});
          console.log("You are not authorized");
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.data) {
          //add your code
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
      }
    );
  },
  mounted() {
    document.title = "AgendaPacientes";
  },
};
</script>
<style>
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
}
@import url("../src/styles/main-style.scss");
@import url("../src/styles/theme-white.scss");
@import url("../src/styles/theme-blue.scss");
@import url("../src/styles/theme-orange.scss");
@import url("../src/styles/theme-purple.scss");
@import url("../src/styles/theme-green.scss");
@import url("../src/styles/theme-black.scss");
</style>
