<template>
  <div
      class="context-card px-1 pa-1"
      v-show="role == 'Administrator' || role == 'Manager'"
    >
      <div class="context-title">
        <h5>Controla tus días y horas de descanso</h5>
        <p>
          Bloquea días completos, incluso si deseas bloquear
          cierto tiempo del día lo podrás hacer tambien, nadie podrá agendar en este horario, tus asistentes no
          podrán modificar esta información, solo tu podrás hacer los cambios.
        </p>
      </div>
      <v-row>
        <v-spacer></v-spacer>
        <template>
          <v-btn
            rounded
            dark
            @click="addItem()"
            class="context-button-style"
            small
          >
            <v-icon left>add</v-icon>
            Nuevo
          </v-btn>
        </template>
        <!-- <template v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      dark
                      large
                      @click="addItem()"
                      class="context-icon-color"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>Nuevo registro</span>
                </v-tooltip>
              </template>-->
      </v-row>
      <v-data-table
        :dark="isDark"
        :headers="headers"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="options"
        sort-by="userName"
        class="row-pointer context-table row-textcolor-table hoverTable"
        :search="search"
        @update:options="getItems"
        fixed-header
      >
        <template v-slot:[`item.fullDay`]="{ item }">
          <v-icon v-if="item.fullDay">check</v-icon>
        </template>
        <template v-slot:top>
          <v-toolbar flat class="context-toolbar">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Buscar registro"
              single-line
              hide-details
              class="mt-5 mb-8 custom-placeholer-color"
              full-width
              dense
              flat
              outlined
              rounded
              placeholder="Escribe para filtrar la lista"
              @change="getItems"
            ></v-text-field>
            <v-btn
              rounded
              icon
              x-large
              class="context-icon-color mt-5 ml-3 mb-8"
              @click="getItems"
            >
              <v-icon>search</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  medium
                  class="context-icon-color mr-2"
                  @click.stop="editItem(item)"
                >
                  edit
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  medium
                  class="context-icon-color mr-2"
                  @click.stop="deleteItem(item)"
                >
                  delete
                </v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:no-data>
          <span color="gray" class="text-body1 ml-5">No hay registros</span>
        </template>
      </v-data-table>
      <v-dialog
      v-model="AddEventDialog"
      width="500px"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <EditScheduleDisabled
        :form="itemSelected"
        :titleEvent="headerTitle"
        :buttonTitle="buttonTitle"
        :disabledDates="disabledDates"
        @closeEditEvent="closeWinEditEvent"
      />
    </v-dialog>
    </div>
</template>

<script>
import EditScheduleDisabled from "/src/components/Settings/EditScheduleDisabled.vue";
import apiServices from "/src/Services/apiServices";
import helperServices from "/src/Services/helperServices";

export default {
  components: { EditScheduleDisabled },
  data() {
    const defaultForm = Object.freeze({
      id: -1,
      description: "",
      fullDay: "",
      start: "",
      end: "",
      date: "",
      statusColor: "",
    });
    return {
      itemSelected: Object.assign({}, defaultForm),
      id: -1,
      snackbar: false,
      messageSnackbar: "",
      isMobile: false,
      loadingShow: false,
      loadingTitle: "",
      currentTheme: localStorage.getItem("theme-color"),
      role: localStorage.getItem("role"),
      AddEventDialog: false,
      headerTitle: "",
      buttonTitle: "",
      search: "",
      dialogDelete: false,
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "date",
          class: "context-header",
        },
        { text: "Día completo", value: "fullDay", class: "context-header" },
        { text: "Hora Inicio", value: "start", class: "context-header" },
        { text: "Hora Fin", value: "end", class: "context-header" },
        { text: "Descripción", value: "description", class: "context-header" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          class: "context-header",
        },
      ],
      items: [],
      options: {},
      totalItems: 0,
      itemsPerPage: 0,
      page: 0,
      isDark: false,
      disabledDates: [],
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getItems;
      },
      deep: true,
    },
  },
  async created() {
    this.isMobile = helperServices.MobileService.isMobile();
    if (this.currentTheme == "theme-black") this.isDark = true;
    else this.isDark = false;
  },

  methods: {
    initialize() {},

    getItems() {
      const { page, itemsPerPage } = this.options;

      // sortBy,sortDesc
      var filter = {
        searchText: this.search,
        userUID: null,
        PageSize: itemsPerPage,
        PageNumber: page,
      };
      //this.itemsPerPage = filter.PageSize;
      //this.page = filter.page;
      this.loadingShow = true;
      apiServices.ScheduleDisabledService.GetSchedulesDisabledFilter(filter)
        .then((resp) => {
          this.items = resp.data.data;
          this.totalItems = resp.data.meta.totalCount;
          //  this.currentPage = resp.data.meta.currentPage;
          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    addItem() {
      this.headerTitle = "Agregar registro";
      this.buttonTitle = "Guardar";
      this.itemSelected = Object.assign({}, this.defaultForm);
      //this.itemSelected.fullDay = true;
      this.itemSelected.id = 0;
      this.itemSelected.description = 'Horario bloqueado, No agendar'
      this.getDisabledDates();
      this.AddEventDialog = true;
    },
    editItem(item) {
      this.headerTitle = "Editar registro";
      this.buttonTitle = "Actualizar";
      this.itemSelected = item;
      this.getDisabledDates();
      this.AddEventDialog = true;
    },
    deleteItem(item) {
      this.itemSelected = item;

      //this.dialogDelete = true;
      this.$fire({
        title: "Desea eliminar la fecha seleccionada?",
        text: item.date,
        type: "question",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          this.deleteItemConfirm();
          this.$fire({
            position: "top-end",
            title: "Registro eliminado correctamente!",
            // text: "El registro fue eliminado de la base de datos",
            type: "success",
            timer: 3000,
            toast: true,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });
        }
      });
    },
    deleteItemConfirm() {
      if (this.itemSelected) {
        apiServices.ScheduleDisabledService.DeleteScheduleDisabled(
          this.itemSelected.id
        )
          .then(() => {
            this.search = "";
            this.getItems();
            this.getDisabledDates();
          })
          .catch((error) => {
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeWinEditEvent(result) {
      if (result.tipo === "cerrarEditEvent") {
        this.AddEventDialog = result.isVisible;
      } else if (result.tipo === "insertEvent") {
        this.AddEventDialog = result.isVisible;
        this.getItems();
        //this.snackbar = true;
        //this.messageSnackbar = "Consulta agregada.";
        this.$fire({
          position: "top-end",
          title: "Registro agregado correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      } else if (result.tipo === "editEventSave") {
        this.AddEventDialog = result.isVisible;
        this.getItems();
        //this.snackbar = true;
        //this.messageSnackbar = "Consulta modificada.";
        this.$fire({
          position: "top-end",
          title: "Registro modificado correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "success",
          timer: 3000,
          toast: true,
          //background: "green",
          //color: "white",
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      }
    },
    getDisabledDates() {
      apiServices.ScheduleDisabledService.GetSchedulesDisabledFullDayList()
        .then((resp) => {
          this.disabledDates = resp.data;
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
  },
};
</script>
<style></style>
